import React from 'react';
import Box from '../../elementsWeb/Box';
import Text from '../../elementsWeb/Text';
import Heading from '../../elementsWeb/Heading';
import Container from '../../elementsWeb/UI/Container';
import SectionWrapper from './service.style';
import {Gift, Code, Flag, Settings, FileText, FastForward} from 'react-feather';
import Dots from '../DotShape';

const ServiceSection = ({
  secText,
  secHeading,
  Row,
  Col,
  serviceTitleStyle,
  secDes,
  RowF,
  FeatureTitle,
  secWrapper,
  ProductTitle,
  ProductTopTitle,
  ProductDes,
  featureWrapper,
  ProductTitleCircle,
  ProductTopTitle2,
  colorWrapper,
  wrapper,
  shape3,
  shape4,
  Column,
  RowAll,
  danger_tag,
  succes_tag
}) => {
  return (
    <SectionWrapper>
      <Container>

        <Box {...wrapper}>
          <Text {...secText} content={'2 PRODUCTS IN 1 SUBSCRIPTION'} />
          <Heading {...secHeading} as="h2" content={'Acquirer-agnostic 3-D Secure as a Service'} a={'feature'}/>
          <Text {...secDes} 
          content={'A single subscription providing access to a 3-D Secure Server and a Merchant Plug-in for performing 3-D Secure 2 and 1 authentications, respectively.'}/>
        </Box>

        <Box {...RowAll}>
          <Box {...Column}>
            <Box {...colorWrapper}>
              <Box {...secWrapper} className={'orange shadow'}>
                <Dots color ={'colors.primaryHover'} shape={shape3} />
                <Dots color ={'colors.primaryHover'} shape={shape4} className="shape2"/>
                <Text {...ProductTopTitle} content={'NEWEST VERSION'} />
                <Text {...ProductTopTitle2} content={'NEW'} className="top_title"/>
                <Box {...Row}>
                  <Text {...ProductTitle} content={'3-D Secure 2'} />
                  <Text {...ProductTitleCircle} content={'3DSS'} className="circle_tag" />
                  <Text {...ProductTitleCircle} content={'EMV 3DS v2.2.0, v2.1.0'} className="circle_tag" />
                </Box>
                <Text {...ProductDes} content={'Our 3-D Secure Server is taking care of the complexity, so you can facilitate (frictionless) 3-D Secure 2 authentications effortlessly.'}/>
                <Text {...succes_tag} content={'EMVCo Certified'} />
              </Box>
            </Box>
            <Box {...colorWrapper}>
              <Box {...secWrapper} className={'shadow dark'}>
                <Text {...ProductTopTitle} content={'PREVIOUS VERSION'} />
                <Text {...ProductTopTitle2} content={'OLD'} className="top_title"/>
                <Box {...Row}>
                  <Text {...ProductTitle} content={'3-D Secure 1'} />
                  <Text {...ProductTitleCircle} content={'MPI'} className="circle_tag"/>
                  <Text {...ProductTitleCircle} content={'3DS v1.0.2'} className="circle_tag"/>
                </Box>
                <Text {...ProductDes} content={'With our Merchant plug-in (MPI) you only need two API requests to facilitate 3-D Secure 1 authentications.'}/>
                <Text {...danger_tag} content={'Expected to be phased out in the industry between 2020 and 2021'} />
              </Box>
            </Box>
          </Box>
          
          <Box {...RowF}>
            <Text {...FeatureTitle} content={'Features'} />
            <Box {...featureWrapper}>

              <Box {...Col} className="service_item_col">
                <Box className="service_icon">
                  <Flag size={40}/>
                </Box>
                <Heading as="h3" content={'Easy onboarding'} {...serviceTitleStyle} />
              </Box>

              <Box {...Col} className="service_item_col">
                <Box className="service_icon">
                  <Code size={40} />
                </Box>
                <Heading as="h3" content={'Simple API'} {...serviceTitleStyle} />
              </Box>

              <Box {...Col} className="service_item_col">
                <Box className="service_icon">
                  <Gift size={40}/>
                </Box>
                <Heading as="h3" content={'Simple pricing'} {...serviceTitleStyle} />
              </Box>

              <Box {...Col} className="service_item_col">
                <Box className="service_icon">
                  <FastForward size={40}/>
                </Box>
                <Heading as="h3" content={'Quick setup'} {...serviceTitleStyle} />
              </Box>

              <Box {...Col} className="service_item_col">
                <Box className="service_icon">
                  <Settings size={40}/>
                </Box>
                <Heading as="h3" content={'Low-maintenance'} {...serviceTitleStyle} />
              </Box>

              <Box {...Col} className="service_item_col">
                <Box className="service_icon">
                  <FileText size={40}/>
                </Box>
                <Heading as="h3" content={'3-D Secure as a Service'} {...serviceTitleStyle} />
              </Box>

            </Box>
          </Box>
        </Box>

      </Container>
    </SectionWrapper>
  );
};

ServiceSection.defaultProps = {
  danger_tag: {
    padding: '5px 10px',
    borderRadius: '50px',
    color: '#fff',
    backgroundColor: 'rgba(220, 53, 69, 0.7)',
    fontSize: '11px',
    fontWeight: '600',
    width: ['100%','100%','100%','410px'],
    textAlign: 'center',
    mb: 0,
    mt: '30px'
  },
  succes_tag: {
    padding: '5px',
    borderRadius: '50px',
    color: '#fff',
    backgroundColor: 'secondary',
    fontSize: '11px',
    fontWeight: '600',
    width: ['100%','125px'],
    textAlign: 'center',
    mb: 0,
    mt: '30px'
  },
  shape3: {
    position: 'absolute',
    height: '200px',
    width: '150px',
    marginTop: '-70px',
    marginLeft: '-80px',
    Zindex: -1,
    transform: 'rotate(21deg)'
  },
  shape4: {
    position: 'absolute',
    height: '200px',
    width: '150px',
    Zindex: -1,
    transform: 'rotate(45deg)'
  },
  secTitleWrapper: {
    mb: '40px',
    mr: 'auto',
    ml: 'auto',
    width: [1, 1/2]
  },
  wrapper: {
    mb: '30px',
    mr: 'auto',
    ml: 'auto',
    width: 1
  },
  secWrapper: {
    mt: '30px',
    mb: '30px',
    ml: ['auto', 'auto', 0],
    mr: ['auto', 'auto', 0],
    p: '40px 30px',
    borderRadius: '6px',
    maxWidth: ['400px', '450px', '500px'],
  },
  colorWrapper: {
    width: 1,
  },
  ProductTitleCircle: {
    borderRadius: '600px',
    padding: '5px 12px 2px',
    backgroundColor: 'rgb(0,0,0,0.4)',
    color:'#fff',
    textAlign: 'center',
    fontSize: ['10px', '10px', '10px', '11px'],
    height: '25px',
    fontWeight: '600',
    mt: ['0', '0', '0', '3px'],
    ml: ['5px', '10px','8px', '15px']
  },
  plusWrapper: {
    mb: '30px',
    mr: 'auto',
    ml: 'auto',
    borderRadius: '6px',
    width: [1, '10%'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: 'primary',
    mb: '12px',
    textTransform: 'uppercase'
  },
  FeatureTitle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    mb: ['20px', '25px', '25px', '30px', '30px'],
    fontWeight:'700',
    textAlign: ['center', 'center', 'left', 'left', 'center']
  },
  ProductTitle: {
    as: 'span',
    display: 'block',
    fontSize: ['17px', '20px', '18px', '25px'],
    fontWeight: '500',
    color: '#fff',
    width: ['110px', '140px', '120px', '160px'],
    mb: '30px'
  },
  ProductTopTitle2: {
    as: 'p',
    fontSize: '12px',
    mb: '30px',
    fontWeight: '700',
    color: 'rgba(0,0,0,0.4)',
    display:['display', 'none']
  },
  ProductTopTitle: {
    as: 'p',
    fontSize: '12px',
    mb: '30px',
    fontWeight: '700',
    color: 'rgb(0,0,0,0.4)',
    display:['none', 'block']
  },
  ProductDes: {
    fontSize: '14px',
    color: '#fff',
    lineHeight: '1.875',
    ml: 'auto',
    mr: 'auto',
    mb: 0,
    mt: 0,
    maxWidth: '100%',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '36px', '36px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '35px',
    lineHeight: '1.12',
    maxWidth: '100%',
  },
  Row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: 'auto',
    mr: 'auto',
  },
  RowAll: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    ml: 'auto',
    mr: 'auto',
  },
  featureWrapper: {
    ml: 'auto',
    mr: 'auto',
    flexBox: true,
    flexWrap: ['wrap'],
    justifyContent: ['center', 'center', 'center', 'right'],
  },
  RowF: {
    ml: ['auto', 'auto'],
    mr: ['auto', 'auto', 0],
    mt: ['0', '0', '40px', '40px', '-83px'],
    width: [1, 1, '30%', '40%'],
    borderRadius: '6px',
  },
  Col: {
    width: ['200px', '40%', 1, 1, 'calc(50% - 40px)'],
    m: ['20px 0', '0 0 60px', '0 0 25px', '0 0 45px 0', '0 40px 100px 0'],
    flexBox: true
  },
  Column: {
    width: [1, 1, '60%', '50%'],
  },
  serviceTitleStyle: {
    fontSize: '16px',
    fontWeight: '400',
    mb: 0,
    width: ['100%', '100%', "130px", "70%"]
  },
  secDes: {
    fontSize: '16px',
    color: '#5d646d',
    lineHeight: '1.875',
    mt: '5px',
    ml: 'auto',
    mr: 'auto',
    maxWidth: '900px',
    textAlign: 'center'
  },
};

export default ServiceSection;

