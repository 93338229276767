import React from "react";
import styled from 'styled-components';
import Image from '../../elementsWeb/Image';

const BoxWrapper = styled.div`
      display: flex;
      flex-direction: row;
      border-top: 1px solid rgba(52,61,72,0.1);
      margin: 60px 0;
      padding: 40px 0 0;
      align-items: center;
      @media (max-width: 720px) {
            flex-direction: column;
      }
      .author-pic {
            width: 200px;
            margin-right: 40px;
      }
      .author-info h6 {
            font-size: 20px;
            margin: 10px 0;
      }
      .author-info span {
            color: #AAAAAA;
      }
      .author-info span.author-info-position {
            color: #39757c;
      }
      .author-info p {
            margin-top: 4px;
            font-size: 14px;
            color: #AAAAAA;
      }
`

class Author extends React.Component {
      render() {
            return (
                  <BoxWrapper>
                        <div className="author-pic">
                              <Image src={this.props.image} alt={this.props.name} />
                        </div>
                        <div className="author-info">
                              <span>Written by</span>
                              <h6>{this.props.name}</h6>
                              <span className="author-info-position">{this.props.position}</span>
                              <p>{this.props.desc}</p>
                        </div>
                  </BoxWrapper>
            )
      };
}
export default Author