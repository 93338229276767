import React from 'react';
import Box from '../../elementsWeb/Box';
import Text from '../../elementsWeb/Text';
import Container from '../../elementsWeb/UI/Container';
import SectionWrapper from './blogSection.style';
import ScrollSpyPosts from '../../elementsWeb/ScrollSpyPosts';
import Posts from "../../componentsWeb/Posts/posts";
import Author from "../../componentsWeb/Author/index";
import Sticky from 'react-stickynode';
import AuthorImg from '../../assetsWeb/images/claus.png';

const BlogSection = ({
  ContainerBox,
  RowF,
  colorWrapper,
  Column,
  secText,
  RowAll,
  posts
}) => {
  return (
    <SectionWrapper>
      <Container {...ContainerBox}>
        <Box {...RowAll}>
          <Box {...Column}>
            <Box {...colorWrapper}>
              <Sticky top={+100} bottomBoundary={'.blog-content'} innerZ={9999} activeClass="sticky-nav-active" className="blog-navigation">
                <Text {...secText} content={'NAVIGATION'} />
                <ScrollSpyPosts menuItems={posts}/>
              </Sticky>
              <Box className="blog-navigation-mobile">
                <Text {...secText} content={'NAVIGATION'} />
                <ScrollSpyPosts menuItems={posts} />
              </Box>
            </Box>
          </Box>
          <Box {...RowF} className="blog-content">
            <Posts postItems={posts} />
            <Author name='Claus M. Christensen' position='CEO and Co-Founder, Clearhaus' desc='passionate about payments and technology' image={AuthorImg}/>
          </Box>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

BlogSection.defaultProps = {
  colorWrapper: {
    width: 1,
  },
  secText: {
    as: 'span',
    display: 'block',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: 'primary',
    mb: '12px',
    textTransform: 'uppercase'
  },
  ContainerBox: {
    margin: 'auto',
  },
  RowAll: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: 'auto',
    mr: 'auto',
  },
  RowF: {
    ml: ['auto', 'auto'],
    mr: ['auto', 'auto', 0],
    mt: ['0', '0', '0', '0', '0'],
    width: [1, 1, 1, '70%'],
    borderRadius: '6px',
  },
  Column: {
    width: [1, 1, 1, '20%'],
  },
};

export default BlogSection;

