import styled from 'styled-components';

const SectionWrapper = styled.section`
  max-width: 720px;
  margin: 40px auto 0;
  display: flex;
  flex-direction: column;
  .timeline-item:after {
    content: "";
    display: table;
    clear: both;
  }
  .timeline-date {
    width: 30%;
    padding: 20px 20px 20px 0;
    text-align: right;
    float: left;
    font-size: 24px;
    font-weight: 600;
  }
  .timeline-content {
    position: relative;
    width: 70%;
    padding: 20px 20px 60px;
    text-align: left;
    float: left;
    border-left: solid 5px white;
    border-right: none;
  }
  .timeline-content h5 {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin: 2px 0 8px !important;
  }
  .timeline-content p {
    margin: 0 0 8px;
  }
  .hexagon svg {
    position: absolute;
    width: 30px;
    top: -53px;
    left: -18px;
  }
  @media (max-width: 720px) {
      max-width: 520px;
    .timeline-date {
      width: 40%;
      font-size: 16px;
    }
    .timeline-content {
      width: 60%;
      padding: 20px 20px 40px;
    }
    .timeline-content h5 {
      font-size: 16px;
      margin: 50px 0 16px;
    }
    .timeline-content p {
      font-size: 14px;
      margin: 0 0 8px;
    }
  }
`;

export default SectionWrapper;
