import styled from 'styled-components';
import BannerBG from '../../assetsWeb/images/banner-texture.png';
import { themeGet } from 'styled-system';

const BannerWrapper = styled.section`
  padding: 120px 0 50px 0;
  margin-bottom: 50px;
  background-image: url(${BannerBG}), ${themeGet('colors.gradient', 'linear-gradient(35deg, #44a1a0 0%, #247b7b 100%)')};
  background-attachment: fixed;
  background-size: cover;
  background-position: top center;
  @media (min-width: 575px) {
    padding: 100px 0 70px 0;
  }
  @media (min-width: 767px) {
    padding: 100px 0 120px 0;
  }
  @media (min-width: 1000px) {
    padding: 100px 0 140px 0;
  }
  @media (min-width: 1200px) {
    padding: 100px 0 150px 0;
  }
  @media (min-width: 2400px) {
    padding: 100px 0 200px 0;
  }
  @media (min-width: 2800px) {
    padding: 100px 0 250px 0;
  }
  @media (min-width: 3200px) {
    padding: 100px 0 300px 0;
  }
  @media (min-width: 3800px) {
    padding: 100px 0 340px 0;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  .height_600 {
    height: 500px;
    @media (min-width: 900px) {
      height: 600px;
    }
    @media (min-width: 1000px) {
      height: 700px;
    }
    @media (min-width: 1400px) {
      height: 800px;
    }
  }
`;

export default BannerWrapper;
