import styled from 'styled-components';

const ContactUsSectionWrapper = styled.section`
  overflow: hidden;
  padding: 100px 0;
  label {
    font-size: 12px;
  }
  .form {
    max-width: 600px;
    position: relative;
    padding: 20px;
    margin: 20px auto 0;
    .field {
      position: relative;
    }
    fieldset {
      max-width: 500px;
      margin: 0 auto;
    }
    .hs-input {
      margin: 20px 0;
      width: 100%;
      font-size: 12px;
      display: block;
      color: rgb(118,118,118);
      box-shadow: none;
      box-sizing: border-box;
      padding: 11px;
      border-radius: 2px;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(221, 221, 221);
      border-image: initial;
      transition: border-color 0.2s ease 0s;
      &.error {

      }
    }
    .hs-submit {
      max-width: 500px;
      margin: 0 auto;
    }
    label {
      left: 17px;
      color: #767676 !important;
      background-color: white !important;
      position: absolute !important;
      top: -12px !important;
      padding: 4px 7px 0px !important;
      &.hs-error-msg {
        position: relative !important;
        margin: 5px;
        color: rgba(220, 53, 69) !important;
        left: 0 !important;
      }
    }
    .hs_error_rollup  {
      label {
        top: -45px !important;
        color: rgba(220, 53, 69) !important;
        display:none;
      }
    }
    @media (max-width: 991px) {
      fieldset.form-columns-2 .hs-form-field {
        width: 100%;
      }
      fieldset .input {
        margin-right: 0 !important;
      }
    }
    fieldset .field:last-child .input {
      margin-right: 0 !important;
    }
    fieldset.form-columns-1 .hs-input {
      width: 100%;
      margin-right: 0;
    }
    .form-columns-2 label {
      top: 9px !important;
    }
    .hs-button {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      min-height: 46px;
      min-width: 48px;
      font-weight: 500;
      text-decoration: none;
      transition: all 0.3s ease;
      color: #ffffff;
      font-family: inherit;
      background-color: #ea885b;
      border: 1px solid #ea885b;
      box-sizing: border-box;
      width: 100%;
      font-size: 14px;
      border-radius: 4px;
      &:hover {
        box-shadow: rgba(27,67,111,0.45) 0 5px 10px 0;
        background-color: #d1754b;

      }
    }
  }
  /* Error style --------------- */
  .hs-error-msgs {
    position: absolute;
    top: 1px;
    z-index: 2;
    li:last {
      display: none;
    }
  }
  .hs-recaptcha {
    display: none !important;
  }
  .decor-hex {
    position: absolute;
    width: 200px;
    z-index: -1;
  }
  .top-left {
    top: -60px;
    left: 200px;
  }
  .bottom-right {
    bottom: -60px;
    right: 200px;
  }
  @media (max-width: 1220px) {
    .top-left {
      top: -60px;
      left: 30px;
    }
    .bottom-right {
      bottom: -60px;
      right: 30px;
    }
  }
`;

export default ContactUsSectionWrapper;
