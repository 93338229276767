import styled from 'styled-components';
import BannerBG from '../../assetsWeb/images/banner-texture.png';
import { themeGet } from 'styled-system';

const BannerWrapper = styled.section`
  padding: 150px 0 90px;
  background-image: url(${BannerBG}), ${themeGet('colors.gradient', 'linear-gradient(35deg, #44a1a0 0%, #247b7b 100%)')};
  background-attachment: fixed;
  background-size: cover;
  background-position: top center;
  .col-image {
    width: 100%;
  }
  @media (min-width: 450px) {
    padding: 150px 0;
  }
  @media (min-width: 767px) {
    padding: 150px 0 130px;
  }
  @media (min-width: 2300px) {
    padding: 150px 0 180px;
  }
  @media (min-width: 3000px) {
    padding: 150px 0 210px;
  }
  @media (min-width: 3300px) {
    padding: 150px 0 240px;
  }
  @media (min-width: 3600px) {
    padding: 150px 0 270px;
  }
  @media (min-width: 3800px) {
    padding: 150px 0 310px;
  }

  .button-margin-bottom {
    display: block;
    @media (min-width: 450px) {
      display: flex;
      width: 435px;
      margin:auto;
    }
    @media (min-width: 767px) {
      display: flex;
      margin:0;
    }
    @media (max-width: 769px) {
      a {
        margin: auto;
      }
    }
  }

  .objectWrapper {
    margin-top: 0;
  }
  @media (max-width: 767px) {
    text-align: center;
  }
  .objectWrapper {
    margin-bottom: 50px;
    align-items: center;
    display: flex;
    video {
      max-width: 600px;
      width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
      box-shadow: ${themeGet('colors.secondaryBoxShadow')};
      border-radius: 6px;
    }
  }
  @media (min-width: 1000px) {
    .objectWrapper {
      margin-left: 5%;
    }
  }
  @media (min-width: 1220px) {
    .objectWrapper {
      video {
        margin: 0 0 0 calc(100% - 600px);
        transform: translateY(0);
      }
    }
  }
`;

const ImageWrapper = styled.section`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px 0px 40px;
  img {
    height: 35px;
    margin: 0 10px;
    @media (max-width: 1010px) {
      margin: 0 10px;
    }
    @media (max-width: 767px) {
      margin: 15px auto;
    }
    @media (max-width: 450px) {
      margin: 15px auto;
      height: 25px;
    }
  }
  @media (max-width: 767px) {
    margin: 0 auto;
  }
`;


export { ImageWrapper };

export default BannerWrapper;
