import React from 'react';
import Scrollspy from 'react-scrollspy';
import { configureAnchors } from 'react-scrollable-anchor';
import SectionWrapper from './scrolSpyPosts.style';

configureAnchors({offset: -80, scrollDuration: 200})

const ScrollSpyMenu = ({ className, menuItems, blogMenuButton, ...props }) => {
  const scrollItems = [];
  menuItems.forEach(item => {
    scrollItems.push(item.node.frontmatter.id);
  });
  return (
    <SectionWrapper>
      <Scrollspy items={scrollItems} currentClassName="is-current" >
      {menuItems.map((menu, index) => (
        <li key={`menu-item-${index}`} className="blog-menu-item">
          <a href={`#${menu.node.frontmatter.id}`}>{menu.node.frontmatter.title}</a>
        </li>
      ))}
      </Scrollspy>
    </SectionWrapper>
  );
};

ScrollSpyMenu.defaultProps = {
  componentTag: 'ul',
  currentClassName: 'is-current'
};

export default ScrollSpyMenu;
