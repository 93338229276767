//import node
import React, { useEffect } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { graphql } from 'gatsby';

//Import third party
import { DrawerProvider } from '../assetsWeb/javascript/DrawerContext';

//CSS
import { saasModernTheme } from '../assetsWeb/global';
import { ResetCSS } from '../assetsWeb/style/style';
import { GlobalStyle, ContentWrapper } from '../assetsWeb/style/sassModern.style';

//import components and containers
import SEO from '../dataWeb/Seo';
import Navbar from '../componentsWeb/Navbar';
import Top from '../componentsWeb/TopSection';
import Signup from '../componentsWeb/Signup';
import Footer from '../componentsWeb/Footer';

//Analytics
import ReactGA from 'react-ga';

export default ({data}) => {
  useEffect(() => {
    ReactGA.initialize('UA-119334275-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])
  return (
    <ThemeProvider theme={saasModernTheme}>
      <>
        <SEO
          title="Sign up for 3-D Secure"
          description="Get started with 3-D Secure with easy access to our free sandbox environment and sign up for the production environment in the dashboard when you are ready."
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Top title_text={''} description_text={''}/>
          <Signup countries={data.signupJson.Countries}/>
          <Footer />
        </ContentWrapper>
      </>
    </ThemeProvider>
  );
};

export const Data = graphql`
query MyQuery_2 {
  signupJson {
    Countries {
      value
      label
    } 
  }
}
`;