import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';

const TiltShape = ({ className, color, shape}) => {
  // Add all classs to an array
  const addAllClasses = ['tilt_shape'];
  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  const ShapeWrapper = styled.div`
    z-index: -1;
    polygon {
      fill: ${themeGet(color)};
    }
  `;
  return (
    <ShapeWrapper className={addAllClasses.join(' ')} style={shape}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.35 126.77">
        <title>Aktiv 1</title>
        <g id="Lag_2" data-name="Lag 2">
          <g id="Lag_1-2" data-name="Lag 1" style={{opacity: 0.3}}>
            <polygon fill={color} points="67.23 43.65 56.57 42.12 52.57 32.12 59.23 23.65 69.89 25.19 73.89 35.19 67.23 43.65"/>
            <polygon fill={color} points="90.91 23.49 83.41 22.41 80.59 15.37 85.28 9.4 92.79 10.49 95.61 17.53 90.91 23.49"/>
            <polygon fill={color} points="58.24 10 52.91 9.23 50.91 4.23 54.24 0 59.57 0.77 61.57 5.77 58.24 10"/>
            <polygon fill={color} points="40.89 26.33 38.2 30.99 32.81 30.99 30.12 26.33 32.81 21.66 38.2 21.67 40.89 26.33"/>
            <polygon fill={color} points="103.22 90.02 105.48 94.91 102.37 99.31 97.01 98.82 94.75 93.93 97.86 89.53 103.22 90.02"/>
            <polygon fill={color} points="129.71 32.45 140.47 31.9 146.33 40.94 141.43 50.53 130.67 51.09 124.81 42.05 129.71 32.45"/>
            <polygon fill={color} points="115.37 74.81 122.95 74.42 127.08 80.79 123.63 87.55 116.05 87.94 111.92 81.57 115.37 74.81"/>
            <polygon fill={color} points="145.04 64.73 150.42 64.46 153.35 68.97 150.9 73.77 145.52 74.05 142.59 69.53 145.04 64.73"/>
            <polygon fill={color} points="99.24 47.2 104.61 46.93 107.54 51.45 105.09 56.24 99.72 56.52 96.79 52 99.24 47.2"/>
            <polygon fill={color} points="102.87 1.83 104.79 1.73 105.84 3.34 104.96 5.05 103.05 5.15 102 3.54 102.87 1.83"/>
            <polygon fill={color} points="75.87 48.83 77.79 48.73 78.84 50.34 77.96 52.05 76.05 52.15 75 50.54 75.87 48.83"/>
            <polygon fill={color} points="20.87 1.83 22.79 1.73 23.84 3.34 22.96 5.05 21.05 5.15 20 3.54 20.87 1.83"/>
            <polygon fill={color} points="27.87 92.83 29.79 92.73 30.84 94.34 29.96 96.06 28.05 96.15 27 94.54 27.87 92.83"/>
            <polygon fill={color} points="0.87 71.83 2.79 71.73 3.84 73.34 2.96 75.06 1.04 75.15 0 73.54 0.87 71.83"/>
            <polygon fill={color} points="38.69 64.28 29.55 69.98 20.05 64.91 19.69 54.14 28.83 48.45 38.33 53.52 38.69 64.28"/>
            <polygon fill={color} points="10.13 44.34 5.56 47.19 0.81 44.65 0.63 39.27 5.2 36.42 9.95 38.96 10.13 44.34"/>
            <polygon fill={color} points="81.72 70.41 81.29 77.98 74.51 81.39 68.17 77.22 68.61 69.65 75.38 66.24 81.72 70.41"/>
            <polygon fill={color} points="127.72 19.41 127.29 26.98 120.51 30.39 114.17 26.22 114.61 18.65 121.38 15.24 127.72 19.41"/>
            <polygon fill={color} points="71.07 104.11 70.75 109.49 65.94 111.91 61.44 108.95 61.75 103.58 66.56 101.16 71.07 104.11"/>
            <polygon fill={color} points="99.89 111.65 106.75 114.87 107.4 122.43 101.17 126.77 94.31 123.55 93.66 115.99 99.89 111.65"/>
            <polygon fill={color} points="51.22 76.08 56.09 78.36 56.55 83.73 52.13 86.81 47.26 84.52 46.8 79.15 51.22 76.08"/>
          </g>
        </g>
      </svg>
    </ShapeWrapper>
  );
};

export default TiltShape;
