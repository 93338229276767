import React from 'react';
import NotifyWrapper from './notify.style';
import ee from 'events'

const emitter = new ee();

export const notify = (title, msg, color, timeout, icon) => {
    emitter.emit('notify', title, msg, color, timeout, icon);
}

export default class Notifybox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            color: '',
            bottom: -200,
            msg: '',
            timeout: 5000,
            icon: ''
        }
        this.timeout = null;
        emitter.on('notify', (title, msg, color, timeout, icon) => {
            this.onShow(title, msg, color, timeout, icon)
        });
        this.onShow = this.onShow.bind(this);
        this.showNotify = this.showNotify.bind(this);
    }

    onShow = (title, msg, color, timeout, icon) => {
        if(this.timeout) {
            clearTimeout(this.timeout);
            this.setState({ 
                bottom: -200,
            }, () => {
                this.timeout = setTimeout(() => {
                    this.showNotify(title, msg, color, timeout, icon);
                }, 500)
            });
        } else {
            this.showNotify(title, msg, color, timeout, icon);
        }
    }

    showNotify = (title, msg, color, timeout, icon) => {
        this.setState({
            bottom: 16,
            title,
            msg,
            color,
            timeout,
            icon
        }, () => {
            this.timeout = setTimeout(() => {
                this.setState({
                    bottom: -200,
                });
            }, this.state.timeout);
        });
    }
    
    render() {
        return(
            <NotifyWrapper bottom={this.state.bottom} color={this.state.color}>
                <div className="icon">
                    {this.state.icon}
                </div>
                <div>
                    <h3>{this.state.title}</h3>
                    <p>{this.state.msg}</p>
                </div>
            </NotifyWrapper>
        )
    }
}