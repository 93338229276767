import styled from 'styled-components';
import Hexagon from '../../assetsWeb/images/hexagon.svg';

const SectionWrapper = styled.section`
  li.blog-menu-item  {
    margin-bottom: 20px;
  }
  .blog-menu-item a {
    color: #888;
    cursor: pointer;
  }
  .blog-menu-item a:hover {
    color: #666;
  }

  @media (min-width: 991px) {
    .blog-menu-item.is-current {
      position: relative;
    }
    .blog-menu-item.is-current a {
      color: #39757c;
    }
    .blog-menu-item.is-current a:before {
      content: '';
      background-image: url(${Hexagon});
      background-size: 15px;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      left: -26px;
      top: 0;
    }
  }
`;

export default SectionWrapper;
