import Fade from 'react-reveal/Fade';
import BlogBox from "../../../../src/elementsWeb/BlogBox/index.js";
import Image from "../../../../src/elementsWeb/Image";
import Advantages1 from "../../../../src/assetsWeb/images/advantages1.png";
import Advantages2 from "../../../../src/assetsWeb/images/advantages2.png";
import Advantages3 from "../../../../src/assetsWeb/images/advantages3.png";
import Advantages4 from "../../../../src/assetsWeb/images/advantages4.png";
import Advantages5 from "../../../../src/assetsWeb/images/advantages5.png";
import * as React from 'react';
export default {
  Fade,
  BlogBox,
  Image,
  Advantages1,
  Advantages2,
  Advantages3,
  Advantages4,
  Advantages5,
  React
};