import React from 'react';
import ShapeWrapper from './tiltShape.style';

const TiltShape = ({ className, color }) => {
  // Add all classs to an array
  const addAllClasses = ['tilt_shape'];
  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <ShapeWrapper className={addAllClasses.join(' ')}>
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1885 150">
      <g id="Lag_2" data-name="Lag 2">
        <g id="Lag_1-2" data-name="Lag 1">
          <polygon className="cls-1" style={{fill: '#fff'}} points="1885 0 1950 1.89 0 120 0 0 1885 0"/>
          <polygon className="cls-2" style={{fill: '#fff', opacity: 0.75}} points="0,0 0,150 1985 0 0 0 1885 0"/>
          </g>
      </g>
   </svg>
    </ShapeWrapper>
  );
};

export default TiltShape;