import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';

const HexagonShape = ({ className, color}) => {
  // Add all classs to an array
  const addAllClasses = ['tilt_shape'];
  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  const ShapeWrapper = styled.div`
    path {
      fill: ${themeGet(color)};
    }
  `;
  return (
    <ShapeWrapper className={addAllClasses.join(' ')}>
      <svg xmlns="http://www.w3.org/2000/svg" width="88" height="100" viewBox="0 0 88 100">
        <path d="M44.0005 0L87.3018 25V75L44.0005 100L0.699219 75V25L44.0005 0Z" fill={color}/>
      </svg>
    </ShapeWrapper>
  );
};

export default HexagonShape;
