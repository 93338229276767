import React, { Fragment } from 'react';
import Box from '../../elementsWeb/Box';
import Text from '../../elementsWeb/Text';
import Heading from '../../elementsWeb/Heading';
import FeatureBlock from '../../elementsWeb/FeatureBlock';
import Container from '../../elementsWeb/UI/Container';
import BannerWrapper from './bannerSection.style';
import TiltShape from '../TiltShape';

const BannerSection = ({
  row,
  col,
  title,
  description,
  description_text,
  title_text
}) => {
  return (
    <BannerWrapper id="banner_section">
      <Fragment>
      <Container>
        <Box className="row" {...row} height={title_text? '' : 0}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={ <Heading content={title_text? title_text : ''} {...title}/>}
              description={<Text content={description_text? description_text : ''} {...description} />}
            />
          </Box>
        </Box>
      </Container>
      </Fragment> 
      <TiltShape />
    </BannerWrapper>
  );
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
    minHeight: ['0', '0', '0', '40px', '80px', '120px'],
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1],
    pb: '50px',
  },
  title: {
    fontSize: ['22px', '30px', '35px','40px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
    textAlign: 'center',
  },
  description: {
    fontSize: '16px',
    color: '#fff',
    lineHeight: '1.75',
    mb: '40px',
    textAlign: 'center',
  }
};

export default BannerSection;
