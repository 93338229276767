import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '../../elementsWeb/Box';
import Text from '../../elementsWeb/Text';
import Image from '../../elementsWeb/Image';
import Heading from '../../elementsWeb/Heading';
import FeatureBlock from '../../elementsWeb/FeatureBlock';
import Container from '../../elementsWeb/UI/Container';
import BannerWrapper, { ImageWrapper } from './bannerSection.style';
import TiltShape from '../TiltShape';
import Button from '../../elementsWeb/Button';
import Video from '../../assetsWeb/images/loader.mp4';
import ReactPlayer from 'react-player'

const BannerSection = ({
  row,
  col,
  title,
  description,
  btnStyle,
  objectWrapper,
  flexbox,
  col_mobil
}) => {
  const Sitedata = useStaticQuery(graphql`
  query {
    siteJson {
      Pictures {
        visalogo {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mastercard {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        dankortlogo {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        amexlogo {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        discoverlogo {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        jcblogo {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        unionpaylogo {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }  
  }
  `);
  const SchemeLogos = (elm) => (
    <Box className="herobox">
      <ImageWrapper>
        <Image src={elm.mastercard} alt="MasterCard ID Check logo" />
        <Image src={elm.visa} alt="Visa Secure logo" />
        <Image src={elm.dankort} alt="Dankort logo" />
        <Image src={elm.amex} alt="American Express logo" />
        <Image src={elm.discover} alt="Discover logo" />
        <Image src={elm.jcb} alt="JCB logo" />
        <Image src={elm.unionpay} alt="Union Pay logo" />
      </ImageWrapper>
      <a href="/#pricing">
                    <Button
                      className="outlined"
                      title="Sign up for 3dsecure.io"
                      {...btnStyle}
                    />
      </a>
    </Box>
  );
  return (
    <BannerWrapper id="banner_section">
      {Sitedata.siteJson.Pictures.map((hero, index) => (
        <Fragment key={`hero-sectin${index}`}>
          <Container>
            <Box className="row" {...row}>
              <Box className="col" {...col}>
                <FeatureBlock
                  title={
                    <Heading
                      content={'Reduce fraud with /n 3-D Secure as a Service'}
                      {...title}
                    />
                  }
                  description={
                    <Text
                      content={'Authenticate cardholders with the new improved 3-D Secure 2 and use the previous 3-D Secure 1 as fallback. Our 3-D Secure products are accessible through their own individual API. One API provides a 3-D Secure Server (3DSS) to support version 2 and the other provides a Merchant Server Plug-in (MPI) to support version 1. Version 1 is being phased out the 14th of October 2022 by Mastercard and the 15th of October 2022 by Visa and will no longer be supported.'}
                      {...description}
                    />
                  }
                  button={<SchemeLogos visa={hero.visalogo.childImageSharp.fluid.src} mastercard={hero.mastercard.childImageSharp.fluid.src} dankort={hero.dankortlogo.childImageSharp.fluid.src} amex={hero.amexlogo.childImageSharp.fluid.src} discover={hero.discoverlogo.childImageSharp.fluid.src} jcb={hero.jcblogo.childImageSharp.fluid.src} unionpay={hero.unionpaylogo.childImageSharp.fluid.src} />}
                />
              </Box>
              <Box className="col objectWrapper" {...objectWrapper}>
                <ReactPlayer url={Video} volume={0} muted={true} loop={true} playing={true} playsinline width="100%" height="auto" />
              </Box>
              <Box className="col" {...col_mobil}>
                <FeatureBlock
                  title={
                    <Heading
                      content={'Reduce fraud with /n 3-D Secure as a Service'}
                      {...title}
                    />
                  }
                  description={
                    <Text
                      content={'Authenticate cardholders with the new improved 3-D Secure 2 and use the previous 3-D Secure 1 as fallback. Our 3-D Secure products are accessible through their own individual API. One API provides a 3-D Secure Server (3DSS) to support version 2 and the other provides a Merchant Server Plug-in (MPI) to support version 1. Version 1 is being phased out the 14th of October 2022 by Mastercard and the 15th of October 2022 by Visa and will no longer be supported.'}
                      {...description}
                    />
                  }
                  button={<SchemeLogos visa={hero.visalogo.childImageSharp.fluid.src} mastercard={hero.mastercard.childImageSharp.fluid.src} dankort={hero.dankortlogo.childImageSharp.fluid.src} amex={hero.amexlogo.childImageSharp.fluid.src} discover={hero.discoverlogo.childImageSharp.fluid.src} jcb={hero.jcblogo.childImageSharp.fluid.src} unionpay={hero.unionpaylogo.childImageSharp.fluid.src} />}

                />
              </Box>
            </Box>
          </Container>
        </Fragment>
      ))}
      <TiltShape />
    </BannerWrapper>
  );
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
  },
  flexbox: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  btnStyle: {
    width: ['100%', '100%', '100%', '100%'],
    minWidth: ['175px', '175px', '175px', '225px'],
    maxWidth: '300px',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'secondaryWithBg',
    height: '55px'
  },
  col_mobil: {
    display: ['block', 'block', 'none']
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1, '50%', '45%'],
    display: ['none', 'none', 'block']
  },
  objectWrapper: {
    width: [1, 1, '50%', '50%'],
  },
  title: {
    fontSize: ['22px', '30px', '35px', '40px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['30px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: ['14px', '16px'],
    color: '#fff',
    lineHeight: '1.75',
    mb: '40px'
  },
  imageStyle: {
    width: ['40px', '45px'],
    m: 'auto'
  },
};

export default BannerSection;
