import styled from 'styled-components';
import { themeGet } from 'styled-system';

const SandboxSectionWrapper = styled.section`
  overflow: hidden;
  padding: 100px 0;
  background-color:  ${themeGet('colors.fourth')};
  label {
    font-size: 12px;
  }
  a.inline-link {
    color: #D38F6B;
  }
  .read-more {
    display: flex;
    width: 21%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
  .objectWrapper {
    margin-top: 0;
  }
  @media (max-width: 767px) {
    text-align: center;
  }
  .objectWrapper {
    margin-bottom: 50px;
    align-items: center;
    display: flex;
    video {
      max-width: 600px;
      width: 100%;
      height: auto;
      margin: 0 auto;
      box-shadow: ${themeGet('colors.secondaryBoxShadow')};
      border-radius: 6px;
    }
  }
  @media (max-width: 1000px) {
    .player {
      width: 100% !important;
    }
  }
  @media (max-width: 850px) {
    .read-more {
      width: 40%;
    }
  }
  @media (min-width: 1500px) {
    .objectWrapper {
      max-width: 900px;
      video {
        max-width: 900px;
      }
    }
    .player {
      width: 90% !important;
    }
  }
  @media (min-width: 1220px) {
    .objectWrapper {
      video {
        margin: auto;
        transform: translateY(0);
      }
    }
  }
  .player {
    margin: auto;
  }
`;

export default SandboxSectionWrapper;
