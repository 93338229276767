import styled from 'styled-components';

const SectionWrapper = styled.section`
  .blog-content {
    margin-top: -70px;
  }
  .blog-content .mt-70 {
    padding-top: 70px;
  }
  .blog-content a {
    color: #ea885b;
    cursor: pointer;
  }
  .blog-content a:hover {
    color: #d1754b;
  }
  .blog-content h2, .blog-content h3, .blog-content h4, .blog-content h5 {
    font-weight: 500;
    color: rgb(68, 68, 68);
    margin: 20px 0 15px;
  }
  .blog-content h2 {
    font-size: 34px;
  }
  .blog-content h3 {
    font-size: 28px;
  }
  .blog-content h4 {
    font-size: 22px;
  }
  .blog-content h5 {
    font-size: 18px;
  }
  .blog-content p, .blog-content ul li,  .blog-content ol li {
    font-size: 18px;
    color: rgb(102, 102, 102);
    font-weight: 400;
    line-height: 1.6;
  }
  .blog-content ul li {
    list-style-type: disc;
  }
  .blog-content ol li {
    list-style-type: decimal;
  }
  .blog-content ul, .blog-content ol {
    margin-left: 40px;
  }
  .blog-content strong {
    color: #ea885b;
  }
  .blog-content img {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .blog-content img.blog-icon {
    margin-right: 20px;
    width: 100px;
    max-width: 100px;
  }
  .blog-content img.blog-small-icon {
    margin-right: 20px;
    width: 50px;
  }
  .blog-content .blog-animation video {
    max-width: 200px;
  }
  .blog-content .float-left {
    float: left;
  }
  .blog-content .float-right {
    float: right;
  }
  .blog-content .blog-flex {
    display: flex;
  }
  .blog-content .blog-flex-space-evenly {
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .blog-content .blog-flex-center {
    justify-content: flex-start;
    align-items: center;
  }
  .blog-content .blog-flex img.image-third {
    width: 33%;
    max-height: 250px;
    max-width: 220px;
  }
  .blog-content .blog-flex img.blog-flex-icon {
    max-width: 120px;
    max-height: 128px;
  }
  .blog-navigation {
    display: block;
    padding-top: 20px;
  }
  .blog-navigation-mobile {
    display: none;
  }
  @media (max-width: 990px) {
    .blog-navigation {
      display: none;
    }
    .blog-navigation-mobile {
      display: block;
    }
  }
  @media (max-width: 720px) {
    .blog-content .blog-flex:not(.blog-flex-space-evenly):not(.blog-flex-center) {
      flex-direction: column;
    }
    .blog-content .blog-flex img.image-third {
      width: 100%;
      margin: 20px auto;
    }
  }
  @media (max-width: 500px) {
    .blog-content .blog-animation {
      width: 100% !important;
      text-align: center;
    }
  }
`;

export default SectionWrapper;
