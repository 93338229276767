import styled from 'styled-components';
import { themeGet } from 'styled-system';
import BannerBG from '../../assetsWeb/images/banner-texture.png';

const SectionWrapper = styled.section`
  padding: 75px 0 100px 0;
  .shadow {
    box-shadow: ${themeGet('colors.primaryBoxShadow')};
    position: relative;
  }
  .PlusCircle {
    background-color: #b4d4cc;
    border-radius: 200px;
    color: white;
    height: 30px;
    width: 30px;
    margin: 15px calc(50% - 15px) 0;
    -webkit-transform: translateY(-50%);
    @media (min-width: 768px) {
      margin: 30px calc(50% - 15px) 0;
    }
  }
  .circle_tag {
    @media (max-width: 480px) {
      position: absolute;
      right: 80px;
      top: 20px;
      &:nth-child(2) {
        right: 20px;
      }
    }
    @media (max-width: 380px) {
      left: 70px;
      right: auto;
      top: 60px;
      &:nth-child(2) {
        right: auto;
        left: 10px;
      }
    }
  }
  .top_title {
    @media (max-width: 380px) {
      margin-bottom: 70px
    }
  }
  .orange {
    background-image: url(${BannerBG}), linear-gradient( ${themeGet('colors.primary')} 0%, ${themeGet('colors.primaryHover')} 100%);
    background-size: 200%;
    background-position: right;
  }
  .dark {
    background-image: url(${BannerBG}),  ${themeGet('colors.gradient')};
    background-size: 350%;
    background-position: right;
    opacity: 0.8;
  }
  .service_item {
    position: relative;
    text-align: center;
  }

  h3 {
    color:#444;
  }
  .shape2 {
    margin-top: 20%;
    right: 0%;
  }
  .service_item_col:nth-child(2n) {
    margin-right: 0;
    @media (min-width: 575px) {
      margin-left: auto;
    }
    @media (min-width: 320px && max-width: 575px) {
      margin-left: 0 !important;
    }
  }
  .service_item_col {
    @media (min-width: 1220px) {
      &:nth-child(5), &:nth-child(6) {
        margin-bottom:0 !important;
      }
    }
    @media (min-width: 767px && max-width: 1220px) {
      &:nth-child(6) {
        margin-bottom:0 !important;
      }
      &:nth-child(5) {
        margin-bottom:25px !important;
      }
    }
    @media (min-width: 575px && max-width: 767px) {
      &:nth-child(5), &:nth-child(6) {
        margin-bottom:0 !important;
      }
    }
    @media (min-width: 320px && max-width: 575px) {
      &:nth-child(6) {
        margin-bottom:0 !important;
      }
      &:nth-child(5) {
        margin-bottom:25px !important;
      }
    }
    h3 {
      align-self: center;
    }
    .service_icon {
      width: 60px;
      height: 40px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      font-size: 40px;
      color: ${themeGet('colors.primary', '#edf7f7')};
      @media (max-width: 990px) {
        width: 90px;
        border-radius: 25px;
        font-size: 36px;
        margin-bottom: 20px;
      }
      @media (max-width: 767px) {
        width: 90px;
        height: 0px;
        border-radius: 25px;
        font-size: 36px;
      }
      @media (max-width: 575px) {
        font-size: 25px;
      }
    }
  }
`;

export default SectionWrapper;
