import React, { Fragment, useState, useEffect } from "react"
import Box from "../../elementsWeb/Box"
import Text from "../../elementsWeb/Text"
import Heading from "../../elementsWeb/Heading"
import Container from "../../elementsWeb/UI/Container"
import PricingTable, {
  FeatureSectionWrapper,
  PricingPrice,
  PricingButton,
  ListItem,
  Cardbutton,
  Planwrapper,
  TextWrapper,
} from "./pricing.style"
import Button from "../../elementsWeb/Button"
import { checkmark } from "react-icons-kit/icomoon/checkmark"
import Icon from "react-icons-kit"
import axios from "axios"
import Dots from "../DotShape"
import HubspotForm from "react-hubspot-form"
import Chev_down from "../../assetsWeb/images/chevron-down.png"
import { ArrowLeft, Info } from "react-feather"

const PricingSection = ({
  row,
  secTitleWrapper,
  secTitle,
  priceStyle,
  priceLabelStyle,
  priceStyleSmall,
  buttonStyle,
  listContentStyle,
  ProductTitleD,
  descriptionStyle,
  secText,
  listcontainer,
  ProductTitleL,
  shape1,
  descriptionStylewhite,
}) => {
  //Features to enterprise and overall section
  const overAllFeatures = [
    {
      content: "Access to our APIs providing a 3DSS and MPI product",
    },
    {
      content: "Quick setup combined with an impressive up-time",
    },
    {
      content:
        "We handle the complexity leaving you with a simple well documented API.",
    },
  ]

  const enterpriseFeatures = [
    {
      content: "Allowed to use the products as a white label solution",
    },
    {
      content: (
        <>
          Priority e-mail, phone and{" "}
          <a
            href="https://slack.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Slack channel
          </a>{" "}
          support
        </>
      ),
    },
    {
      content: "DevOps team on duty 24/7",
    },
  ]

  const [state, setState] = useState({
    data: [],
    flipped: false,
  })

  useEffect(() => {
    const aws = axios.create({
      baseURL: process.env.GATSBY_AWS_URL,
    })
    aws.defaults.headers.post["Content-Type"] = "application/json"
    aws.defaults.headers.common["X-Api-Key"] = process.env.GATSBY_API_KEY
    aws.get("/plans").then(res => {
      setState({
        ...state,
        data: res.data.filter(e => e.version === 1),
      })
      localStorage.setItem("interval", 1)
      localStorage.setItem(
        "subscription",
        res.data
          .filter(e => e.version === 2)
          .sort((a, b) => {
            return a.amount - b.amount
          })[0]?.handle
      )
    })
  }, [])

  let price = state.data
  let setup_fee =
    price.length >= 1
      ? parseInt(state.data.filter(e => e.interval_length === 1)[0].setup_fee) /
          100 +
        " €"
      : "1000 €"

  const flip_card = () => {
    setState({
      ...state,
      flipped: !state.flipped,
    })
  }

  //To see the buttons for annual and monthly, change to true if you want to see them.
  return (
    <FeatureSectionWrapper color={"third"}>
      <Container noGutter mobileGutter className="container">
        <Box {...secTitleWrapper}>
          <Text {...secText} content={"PRICING"} />
          <Heading
            {...secTitle}
            as="h2"
            content={"A single subscription is all you need"}
            a={"pricing"}
          />
        </Box>

        <Box {...row} className="row">
          <Fragment>
            <Dots color={"colors.primary"} shape={shape1} />
            <PricingTable className="pricing_table">
              <Heading
                content={"3-D secure subscriptions"}
                className="heading"
                {...ProductTitleL}
              />
              <Box {...listcontainer} className="center">
                <Text
                  content={"A subscription includes"}
                  {...descriptionStylewhite}
                />
                {overAllFeatures.map((items, index) => (
                  <ListItem
                    key={`pricing-table-list-${index}`}
                    color={"secondary"}
                  >
                    <Icon
                      icon={checkmark}
                      className="price_list_icon"
                      size={13}
                    />
                    <Text content={items.content} />
                  </ListItem>
                ))}
              </Box>
            </PricingTable>
          </Fragment>

          {price.length >= 1 ? (
            <Fragment>
              {price
                .filter(elm => elm.interval_length === 1)
                .sort((a, b) => {
                  return a.amount - b.amount
                })
                .map((plan, index) => (
                  <Planwrapper key={`plan-${index}`}>
                    <Heading
                      content={plan.name}
                      {...ProductTitleD}
                      className="heading"
                    />
                    {plan.description ? (
                      <Text
                        content={plan.description.toString().split("\\n")[0]}
                        {...descriptionStyle}
                      />
                    ) : (
                      <p>{plan.description}</p>
                    )}
                    <PricingPrice>
                      <Box>
                        <Text
                          content={plan.amount / 100 + " €"}
                          {...priceStyle}
                        />
                        <Text content={"per month"} {...priceLabelStyle} />
                      </Box>
                    </PricingPrice>
                    {plan.description ? (
                      <Box className="list-box">
                        {plan.description
                          .split("\\n")
                          .filter(e => e !== plan.description.split("\\n")[0])
                          .map((items, index) => (
                            <ListItem
                              key={`pricing-table-list-${index}`}
                              color={"secondary"}
                              text={"#666"}
                              margin-bottom={"35px"}
                            >
                              <Icon
                                icon={checkmark}
                                className="price_list_icon"
                                size={13}
                              />
                              <Text content={items} {...listContentStyle} />
                            </ListItem>
                          ))}
                        <ListItem
                          color={"primary"}
                          text={"#666"}
                          margin-bottom={"35px"}
                        >
                          <Info size={"30px"} className="price_list_icon" />
                          <Text
                            content={
                              <span>
                                A one time setup fee <b>{setup_fee}</b> will be
                                charged at the first month of your subscription.
                                Prices are not subject to VAT.
                              </span>
                            }
                          />
                        </ListItem>
                      </Box>
                    ) : (
                      <p>{plan.description}</p>
                    )}
                    <PricingButton>
                      <a href={"/signup"}>
                        <Button
                          title={"Sign up"}
                          {...buttonStyle}
                          colors={"secondaryWithBg"}
                          onClick={() =>
                            localStorage.setItem("subscription", plan.handle)
                          }
                        />
                      </a>
                    </PricingButton>
                  </Planwrapper>
                ))}
            </Fragment>
          ) : (
            <Fragment>
              <Planwrapper />
              <Planwrapper />
            </Fragment>
          )}
          <Box className={state.flipped ? "flipped card" : "flip card"}>
            <Planwrapper className="card-inner">
              <Box className="card-back">
                <Box {...row}>
                  <Heading content={"Enterprise"} {...ProductTitleD} />
                  <Text
                    content={"Want enterprise features?"}
                    {...descriptionStyle}
                  />
                </Box>
                <PricingPrice>
                  <Box>
                    <Text content={"custom price"} {...priceStyleSmall} />
                  </Box>
                </PricingPrice>
                <Box className="list-box">
                  {enterpriseFeatures.map((items, index) => (
                    <ListItem
                      key={`pricing-table-list-enterprice-${index}`}
                      color={"secondary"}
                      margin-bottom={"35px"}
                      text={"#666"}
                    >
                      <Icon
                        icon={checkmark}
                        className="price_list_icon"
                        size={13}
                      />
                      <Text content={items.content} />
                    </ListItem>
                  ))}
                  <ListItem
                    color={"primary"}
                    text={"#666"}
                    margin-bottom={"35px"}
                  >
                    <Info size={"30px"} className="price_list_icon" />
                    <Text
                      content={
                        <span>
                          A one time setup fee <b>{setup_fee}</b> will be
                          charged at the first month of your subscription.
                          Prices are not subject to VAT.
                        </span>
                      }
                    />
                  </ListItem>
                </Box>
                <Cardbutton>
                  <Button
                    title={"Request a custom price"}
                    {...buttonStyle}
                    colors={"secondaryWithBg"}
                    onClick={() => flip_card()}
                  />
                </Cardbutton>
              </Box>

              <Box className="card-front">
                <ArrowLeft
                  onClick={() => flip_card()}
                  size={15}
                  className="back_arrow"
                />
                <Box {...row}>
                  <Heading content={"Enterprise"} {...ProductTitleD} />
                </Box>
                <Box className="form">
                  <HubspotForm
                    portalId="4315346"
                    formId="14c07f62-6495-4bf9-9b72-ddd788d0c96d"
                    loading={<div>Loading...</div>}
                  />
                  <img src={Chev_down} alt="chev" />
                </Box>
              </Box>
            </Planwrapper>
          </Box>
        </Box>
        <TextWrapper color={"secondary"}>
          <Text
            content={
              <span>
                When signing up you agree to our{" "}
                <a target="_blank" rel="noopener noreferrer" href="/tos/">
                  Terms of service
                </a>{" "}
                and{" "}
                <a target="_blank" rel="noopener noreferrer" href="/dpa/">
                  Data Processing Agreement
                </a>
                .
              </span>
            }
          />
        </TextWrapper>
      </Container>
    </FeatureSectionWrapper>
  )
}

// FeatureSection default style
PricingSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    maxWidth: "100%",
    flexDirection: "row",
  },
  shape1: {
    position: "absolute",
    height: "250px",
    width: "200px",
    marginTop: "-30px",
    marginLeft: "-10px",
    Zindex: -1,
  },
  secTitle: {
    fontSize: ["22px", "26px", "26px", "30px", "36px"],
    fontWeight: "600",
    color: "#15172C",
    lineHeight: "1.34",
    mb: "40px",
    textAlign: "center",
    fontFamily: "Poppins",
  },
  secText: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "primary",
    mb: "12px",
    textTransform: "uppercase",
  },
  ProductTitleL: {
    as: "h3",
    margin: 0,
    mb: "0px",
    display: "block",
    fontSize: "26px",
    fontWeight: "500",
    color: "#fff",
    textAlign: "center",
  },
  ProductTitleD: {
    as: "h3",
    margin: 0,
    mb: "15px",
    display: "block",
    fontSize: "28px",
    fontWeight: "500",
    color: "#444",
    textAlign: "center",
    width: "100%",
  },
  descriptionStyle: {
    fontSize: "14px",
    color: "#666",
    mb: "5px",
    fontWeight: "400",
    textAlign: "center",
    width: "100%",
    height: "40px",
  },
  descriptionStylewhite: {
    fontSize: "18px",
    color: "#fff",
    mb: "0px",
    fontWeight: "400",
    textAlign: "center",
    p: "55px 0 40px;",
  },
  priceStyle: {
    as: "h5",
    display: "block",
    fontWeight: "400",
    fontSize: "35px",
    mb: "0px",
    color: "#333",
  },
  priceStyleSmall: {
    as: "h5",
    display: "block",
    fontWeight: "400",
    fontSize: "30px",
    mb: "0px",
    color: "#333",
    textAlign: "center",
    maxWidth: "200px",
  },
  priceLabelStyle: {
    fontSize: "12px",
    fontWeight: "500",
    mt: "5px",
    mb: 0,
    color: "#444",
  },
  buttonStyle: {
    type: "button",
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "4px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginLeft: "auto",
    marginTop: "13px",
    width: "100%",
    maxWidth: "100%",
  },
}

export default PricingSection
