import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '../../elementsWeb/Box';
import Text from '../../elementsWeb/Text';
import Heading from '../../elementsWeb/Heading';
import Container from '../../elementsWeb/UI/Container';
import TiltShape from '../TiltShape7deg';
import {HelpCircle, UserCheck} from 'react-feather';
import { FooterWrapper, ImageWrapper} from './footer.style';
import Image from '../../elementsWeb/Image';

const Footer = ({
  row,
  col1,
  col2,
  column,
  titleStyle,
  titleStyleemail,
  copyrightStyle,
  copyrightBox,
  box,
  container,
  clrhsLogo,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      siteJson {
        Pictures {
          visalogo {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mastercard {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          poweredbyclearhaus {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper>
    <TiltShape className="shape"/>
      <Container noGutter mobileGutter width="1300px" className="footer">
        <Box {...container}>
        <Box {...row}>
          <Box {...col1}>
          <Box {...row}>
              <HelpCircle className={"icon"} />
              <Box {...box}>
                <Heading as="h5" content={'Do you have any questions?'} {...titleStyle} />
               <a href={'mailto: hello@3dsecure.io'} target="_blank" rel="noopener noreferrer">
                <Heading as="h4" content={'hello@3dsecure.io'} {...titleStyleemail} />
                </a>
              </Box>
            </Box>
          </Box>
          <Box {...col2}>
          <Box {...row}>
              <UserCheck className={"icon"} />
              <Box {...box}>
                <Heading as="h4" content={'3dsecure.io'} {...titleStyleemail} />
                <Heading as="h5" 
                content={'We have provided 3-D Secure products since 2015. Our goal is always to provide simple APIs enabling great 3-D Secure technology. We want to make it easy for developers and businesses to reduce fraud through powerful 3-D Secure technology.'} {...titleStyle} />
              </Box>
              <Image src={Data.siteJson.Pictures[0].poweredbyclearhaus.childImageSharp.fluid.src} alt="Clearhaus logo" {...clrhsLogo} />
            </Box>
          </Box>
          </Box>
        </Box>
        <Box {...copyrightBox}>
          <Box {...row}>
            <Box {...column}>
                  <Text as="p" content={"© Copyright 3dsecure.io ApS, " + new Date().getFullYear()} {...copyrightStyle}/>
            </Box>
            <Box {...column}>
            {Data.siteJson.Pictures.map((hero, index) => (
              <ImageWrapper key={`${index}-footer`}>
                <Image src={hero.visalogo.childImageSharp.fluid.src} alt="Visa Secure logo" />
                <Image src={hero.mastercard.childImageSharp.fluid.src} alt="MasterCard ID Check logo" />
              </ImageWrapper>
            ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

Footer.defaultProps = {
  pictures: {
    width: '50px',
    ml: '30px'
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    ml: 'auto',
    mr: 'auto'
  },
  container: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    ml: 'auto',
    mr: 'auto',
    mb: '30px',
  },
  box: {
    width: [1,'calc(100% - 60px)'],
    margin: ['20px auto', '']
  },
  column: {
    width: [1, 1/2],
    mb: 0,
  },
  col1: {
    width: [1, 1, '40%'],
    margin: ['auto', 'auto', '0'],
    mb: ['0', '0', '0', '0', '0', '0'],
  },
  col2: {
    width: [1, 1, '56%'],
    margin: ['auto', 'auto', '0'],
    mb: ['0', '0', '0', '0', '0', '0'],
  },
  titleStyle: {
    fontSize: '16px',
    fontWeight: '300',
    mb: '8px',
    lineHeight: '21px',
    textAlign: ['center', 'center', 'left'],
  },
  titleStyleemail: {
    mt: '0px',
    fontSize: '25px',
    fontWeight: '400',
    mb: '8px',
    textAlign: ['center', 'center', 'left'],
  },
  newsletterButton: {
    type: 'button',
    fontSize: '16px',
    pl: '20px',
    pr: '20px',
    colors: 'primary',
    minHeight: 'auto',
  },
  copyrightStyle: {
    fontSize: '14px',
    color: '#fff',
    width: '100%',
    mb: 0,
    textAlign: ['center', 'center', 'left'],
  },
  copyrightBox: {
    width: '100%',
    flexWrap: 'wrap',
    flexBox: true
  },
  shape: {
    position: 'relative',
  },
  clrhsLogo: {
    width: '260px',
    margin: ['20px auto 0', '20px auto 0', '20px 40px 0 40px'],
  }
};

export default Footer;
