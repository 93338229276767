import styled from 'styled-components';
import { themeGet } from 'styled-system';
import BannerBG from '../../assetsWeb/images/banner-texture.png';

const PricingTable = styled.div`
  background-image: url(${BannerBG}), ${themeGet('colors.gradient', 'linear-gradient(35deg, #e5eced 0%, #e5eced 100%)')};
  border-radius: 5px;
  width: 24%;
  min-width: 300px;
  border-radius: 5px;
  box-shadow: rgba(27,67,111,0.15) 0 0px 5px 2px;
  position: relative;
  padding: 40px 30px;
  margin: 0 auto 40px;
  @media (max-width: 1251px) {
    width: 48%;
    max-width: 400px;
    margin-bottom: 50px;
  }
  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
  @media (max-width: 651px) {
    width: 90%;
    max-width: 500px;
    margin-bottom: 25px;
    min-width: 270px;
  }
  .heading {
    align-self: flex-start;
  }
  .center {
    align-self: center;
  }
  `;

const Planwrapper = styled.div`
  background-color: white;
  border-radius: 6px;
  border: 1px solid #eee;
  width: 24%;
  min-width: 300px;
  min-height: 610px;
  position: relative;
  padding: 40px 30px;
  margin: 0 auto 40px;
  @media (max-width: 1251px) {
    width: 48%;
    max-width: 400px;
    margin-bottom: 50px;
  }
  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
  @media (max-width: 651px) {
    width: 90%;
    max-width: 500px;
    margin-bottom: 25px;
    min-width: 270px;
  }
  p, h3 {
    align-self: flex-start;
  } 
  label {
    font-size: 12px;
  }
  .form {
    width: 100%;
    position: relative;
    .field {
      position: relative;
    }
    .hs-input {
      margin: 20px 0;
      width: 100%;
      font-size: 12px;
      display: block;
      color: rgb(118,118,118);
      box-shadow: none;
      box-sizing: border-box;
      padding: 11px;
      border-radius: 2px;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(221, 221, 221);
      border-image: initial;
      transition: border-color 0.2s ease 0s;
      &.error {

      }
    }
    select {
      cursor: pointer;
    }
    label {
      left: 17px;
      color: #767676 !important;
      background-color: white !important;
      position: absolute !important;
      top: -12px !important;
      padding: 4px 7px 0px !important;
      &.hs-error-msg {
        position: relative !important;
        margin: 5px;
        color: rgba(220, 53, 69) !important;
        left: 0 !important;
      }
    }
    .hs_error_rollup  {
      label {
        top: -45px !important;
        color: rgba(220, 53, 69) !important;
        display:none;
      }
    }
    .hs-button {
      position: fixed;
      bottom: 40px;
      left: 0;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      min-height: 46px;
      min-width: 48px;
      font-weight: 500;
      text-decoration: none;
      padding-top: 8px;
      padding-bottom: 8px;
      transition: all 0.3s ease;
      color: #ffffff;
      font-family: inherit;
      background-color: #ea885b;
      border: 1px solid #ea885b;
      box-sizing: border-box;
      margin-top: 13px;
      margin-left: 30px;
      margin-bottom: 40px;
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
      max-width: calc(100% - 60px);
      font-size: 14px;
      border-radius: 4px;
      &:hover {
        box-shadow: rgba(27,67,111,0.45) 0 5px 10px 0;
        background-color: #d1754b;

      }
    }
    .hs_company_employees {
      label {
        display: none;
      }
    }
  }
  /* Error style --------------- */
  .hs-error-msgs {
    position: absolute;
    top: 1px;
    z-index: 2;
    li:last {
      display: none;
    }
  }

  
  .hs-recaptcha {
    display: none !important;
  }
  .heading {
    align-self: flex-start;
  }
  .center {
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    height: 50%;
    margin-bottom: 30px;
    width: 100%;
    margin-top: 30px;
  }
  .list-box {
    width: 100%;
  }
  img {
    width: 7px;
    opacity: .7;
    margin-top: -42px;
    margin-right: 13px;
    float: right;
  }
  /*------ Flip-card------*/
  &.card-inner {
    transition: transform 0.6s;
    transform-style: preserve-3d;
    width: 100% !important;
    height: 100%;
    background-color: transparent;
    border-radius: 0;
    border: none;
    position: relative;
    padding: 0;
    margin: 0 auto 40px;
    -webkit-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
`;

const PricingPrice = styled.div`
  width: auto;
  margin: 15px auto 0;
  height: 80px;
  display: flex;
  flex-wrap;
  div {
    align-self: center;
    margin: -10px auto 0;
  }
  p {
    color: #aaa;
    text-align: left;
  }
`;

const PricingButton = styled.div`
  position: absolute;
  bottom: 40px;
  width: calc(100% - 60px);
  @media (min-width: 1250px) {
    max-width: 236px;
  }
`;

const Cardbutton = styled.div`
  position: absolute;
  bottom: 40px;
  width: calc(100% - 60px);
  @media (min-width: 1250px) {
    max-width: 236px;
  }
`;


const ListItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  height: 70px;
  box-sizing: border-box;
  font-weight: 400;
  color: ${props => props.text? props.text : '#fff'};
  .price_list_icon {
    color: #ea885b;
    transition: .5s;
    margin-right: 15px;
    align-self: center;
    width: 20px;
    height: 20px;
    cursor: inherit;
    svg {
      cursor: inherit;
    }
  }
  p {
    align-self: center;
    margin: 0;
    width: calc(100% - 50px);
    font-size: 13px;
  }
  b {
    color: #777;
    font-weight: 600;
  }
  strong {
    color: #ea885b;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s;
  }
  a {
    font-weight: 500;
    cursor: pointer; 
    color: #ea885b;
    transition: .5s;
    &:hover {
      color: #222;
    }
  }
`;

const FeatureSectionWrapper = styled.section`
  padding: 100px 0;
  z-index: 0;
  background-color:  ${themeGet('colors.fourth')};
  .container {
    padding: 0 26px;
  }
  .left {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    transition: .3s;
    border-right: 0 !important;
    margin:20px 0 35px calc(50% - 160px);
    @media (max-width: 510px) {
      margin-left: calc(50% - 150px);
    }
    @media (max-width: 410px) {
      margin-left: calc(50% - 118px);
    }
  }
  .right {
    margin-bottom: 35px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    transition: .3s;
    border-left: 0 !important;
  }
  .btn {
    cursor: pointer;
    padding: 15px 25px;   
    border: solid 1px ${props => themeGet('colors.' + props.color)};
    background-color: transparent;
    font-size: 13px;
    font-weight: 600;
    color: #888;
    width: 160px;
    @media (max-width: 410px) {
      width: 122px;
      padding: 16px 19px;
    }
    &:hover {
      color: #444;
      transition: .3s;
    }
  }
  .active {
    padding: 14px 25px;
    border: solid 2px ${props => themeGet('colors.' + props.color)};
    background-color:  ${props => themeGet('colors.' + props.color)};
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    width: 160px;
    @media (max-width: 410px) {
      width: 122px;
      padding: 15px 19px;
    }
  }
  .orange {
    background-color: ${themeGet('colors.secondary')};
  }
  .dark {
    background-color: ${themeGet('colors.primary')};
  }
  .shape2 {
    margin-top: 74px;
    right: -7%;
  }
  .button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;;
    justify-content: center;
    min-height: 46px;
    min-width: 48px;
    border-radius: 3px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border: 0;
    transition: all 0.3s ease;
    color: #ffffff;
    box-sizing: border-box;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    border-radius: 4px;
    &.enterprise {
      border: 1px solid #ea885b;
      color: #ffffff;
      background-color: #ea885b;
    }
    &.disable {

    }
  }
  /* Card flip section -----------------------------*/
  .card {
    perspective: 1000px;
    min-width: 300px;
    min-height: 600px;
    position: relative;
    margin: 0 auto;
    
  }

  .card, .card-inner {
    @media (max-width: 1251px) {
      width: 48%;
      max-width: 400px;
      margin-bottom: 50px;
    }
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
    @media (max-width: 651px) {
      width: 90%;
      max-width: 500px;
      margin-bottom: 25px;
      min-width: 270px;
    }
  }
  
  .flip .card-inner {
    transform: rotateY(180deg);
  }

  &.flipped btn {
    display: none;
  }
  
  .card-front, .card-back {
    position: absolute;
    width: calc(100% - 60px);
    min-height: 610px;
    backface-visibility: hidden;
    height: calc(100% - 80px);
    background-color: white;
    border-radius: 6px;
    border: 1px solid #eee;
    padding: 40px 30px;
    width: 100%;
    @media (max-width: 1250px) {
      min-height: 660px;
    }
    @media (max-width: 990px) {
      min-height: 630px;
    }
    @media (max-width: 650px) {
      min-height: 630px;
    }
  }

  .card-front {
    z-index: 1;
  }
  
  .card-back {
    transform: rotateY(180deg);
    background-color: #fff;
    z-index: 2;
  }

  .back_arrow {
    position: absolute;
    top: 20px; 
    left: 20px;
  }
  svg {
    cursor: pointer;
  }
`;

const TextWrapper = styled.span`
  width: 100%;
  text-align: center; 
  color: #999;
  margin-top: 40px;
  a { 
    color: ${props => themeGet('colors.' + props.color)}
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: ${props => themeGet('colors.secondaryHover')}
    }
  }
`;

export {
  FeatureSectionWrapper,
  PricingPrice,
  PricingButton,
  ListItem,
  Planwrapper,
  Cardbutton,
  TextWrapper
};
export default PricingTable;
