import React from "react"
import TiltShape from '../../componentsWeb/DotShape';
import styled from 'styled-components';

const shape1 =  {
    position: 'absolute',
    height: '150px',
    width: '100px',
    bottom: '0',
    marginBottom: '-90px',
    marginLeft: '-50px',
    Zindex: -1,
    transform: 'rotate(-30deg)'
}
const shape2 =  {
    position: 'absolute',
    height: '150px',
    width: '100px',
    right: '0',
    top: '0',
    marginTop: '-40px',
    marginRight: '-20px',
    Zindex: -1,
    transform: 'rotate(30deg)'
}

const BoxWrapper = styled.div`
    background-color: #39757c;
    padding: 20px 30px;
    border-radius: 6px;
    box-shadow: 0 0 15px 0px #ddd;
    position: relative;
    margin: 60px 0;
    p {
        color: white !important;
        margin: 0;
    }
`

export default ({ children }) => (
    <BoxWrapper>
        <TiltShape color ={'colors.primaryHover'} shape={shape1}/>
        <TiltShape color ={'colors.primaryHover'} shape={shape2}/>
        <p>{children}</p>
    </BoxWrapper>
)