import Image from "../../../../src/elementsWeb/Image";
import Timeline from "../../../../src/elementsWeb/Timeline/index.js";
import Picture1 from "../../../../src/assetsWeb/images/sca1.png";
import Picture2 from "../../../../src/assetsWeb/images/sca2.png";
import Picture3 from "../../../../src/assetsWeb/images/sca3.png";
import * as React from 'react';
export default {
  Image,
  Timeline,
  Picture1,
  Picture2,
  Picture3,
  React
};