import React from 'react';
import Slide from 'react-reveal/Slide';
import SectionWrapper from './timeline.style';
import Hex from '../../componentsWeb/HexagonShape';

const Timeline = ({ className, items, ...props }) => {
  var colors = [];
  var borders = [];
  var gradients = [];
  items.forEach(item => {
    const color = {
      color: item.color,
    };
    const border = {
      borderTop: `dashed 2px ${item.color}`,
    };
    const gradient = {
      WebkitBorderImage: `-webkit-gradient(linear, left top, left bottom, from(${item.gradient1}), to(${item.gradient2}))0 0 0 100% stretch`,
    
    }
    colors.push(color);
    borders.push(border);
    gradients.push(gradient);
  });
  return (
    <SectionWrapper className="timeline">
      {items.map((item, index) => (
        <Slide bottom key={`timeline-item-${index}`}>
          <div className="timeline-item" style={borders[index]}>
            <div className="timeline-date">
              <span style={colors[index]}>{item.date}</span>
            </div>
            <div className="timeline-content" style={gradients[index]}>
              <Hex color={item.color} className="hexagon" />
              <h5 style={colors[index]}>{item.header}</h5>
              <p style={colors[index]}>{item.text}</p>
            </div>
          </div>
        </Slide>
      ))}
    </SectionWrapper>
  );
};

export default Timeline;
