//import node
import React, { useEffect } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';

//Import third party
import { DrawerProvider } from '../assetsWeb/javascript/DrawerContext';
import { Modal } from '@redq/reuse-modal'; //So you can open modal on the price site


//CSS
import { saasModernTheme } from '../assetsWeb/global';
import { ResetCSS } from '../assetsWeb/style/style';
import { GlobalStyle, ContentWrapper } from '../assetsWeb/style/sassModern.style';
import '@redq/reuse-modal/es/index.css';


//import components and containers
import SEO from '../dataWeb/Seo';
import Navbar from '../componentsWeb/Navbar';
import HeroSection from '../componentsWeb/HeroSection';
import Footer from '../componentsWeb/Footer';
import Feature from '../componentsWeb/Feature';
import Pricing from '../componentsWeb/Pricing';
import Testimonial from '../componentsWeb/Testimonials';
import Sandbox from '../componentsWeb/Sandbox';
import ContactUs from '../componentsWeb/ContactUs';
import Notifybox from '../componentsWeb/Notify';

//Analytics
import ReactGA from 'react-ga';

export default () => {
  useEffect(() => {
    ReactGA.initialize('UA-119334275-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])
  return (
    <ThemeProvider theme={saasModernTheme}>
      <>
        <SEO 
          title="3dsecure.io - 3-D Secure verifications with VISA & Mastercard"
          description="Use our acquirer agnostic Merchant Plug-in to faciliate 3-D Secure verifications.
          You only need two simple API requests with our software as a service." 
          />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <HeroSection />
          <Feature />
          <Pricing />
          <Testimonial />
          <Sandbox />
          <ContactUs />
          <Footer />
          <Notifybox />
        </ContentWrapper>
      </>
    </ThemeProvider>
  );
};
