import styled from 'styled-components';
import secondaryWithBg from '../../assetsWeb/global/colors';
import { themeGet } from 'styled-system';

const SignupWrapper = styled.section`
    position: relative;
    max-width: 90%;
    margin: -150px auto 0;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 30px -10px #999;
    padding-top: 30px;
    z-index: 2;
    .fixed_width {
        max-width: 500px;
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
    .select__control  {
        min-height: 42px;
        border-radius: 2px;
        border: 1px solid ${themeGet('colors.inactiveIcon', '#ebebeb')};
    }
    .select__placeholder {
        color: #767676;
        font-size: 13px !important;
        font-weight: 500;
    }
    .coupon_wrapper {
        display: flex;
        width: 100%;
    }
    .coupon_button button {
        margin: 0;
        border-radius: 0;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        width: 100%;
        height: 42px;
        padding-right: 0;
        padding-left: 0;
    }
    .coupon_input {
        width: 70%
    }
    .coupon_button {
        width: 30%
    }
    textarea {
        height: 252px !important
    }
    .sub_text {
        color: #999;
        font-size: 0.9em;
        font-style: italic;
        margin-top: -10px;
        max-width: 210px;
    }
    .padding-right-plus {
        padding-right: 20px !important;
        @media (max-width: 500px) {
            padding-right: 0 !important;
        }
    }
    .flex_box {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; 
        border-top: 1px solid #ddd;
        padding: 40px 0px;
    }
    .row_heading {
        width:30%;
        padding-left: 40px;
        padding-right: 7px;
        @media (max-width: 500px) {
            padding-left: 20px;
            width:100%;
        }
        @media (max-width: 700px && min-width: 500px) {
            width:33%;
        }
    }
    .row_content {
        width:70%;
        padding-right: 40px;
        @media (max-width: 500px) {
            padding-left: 20px;
            width:100%;
        }
        @media (max-width: 700px && min-width: 500px) {
            width:67%;
        }
    }
    .mb {
        margin-bottom: 0 !important;
    }
    h2 {
        font-weight: 400;
        font-size: 1.3em;
        color: #888;
        margin-top: 0px;
        @media (max-width: 500px) {
            font-size: 1em;
        }
    }
    .title {
        margin-top: 40px;
        color: #666 !important;
        font-size: 1.7em !important;
        margin-left: 40px;
        @media (max-width: 500px) {
            margin-left: 20px;
        }
    }
    .desc {
        color: #777 !important;
        margin-left: 40px;
        @media (max-width: 500px) {
            margin-left: 20px;
        }
    }
    .col-md-1 {
        width: 100%;
        margin-bottom: 35px;
    }
    .col-md-2 {
        margin: 0 0 32px 0;
        width: 100%;
    }
    .col-md-3 {
        margin: 0 0 32px 0;
        width: 100%;
    }
    .col-md-4 {
        margin: 0 0 32px 0;
        width: 100%;
    }
    .row { 
        margin-bottom: 32px;
    }
    .price_section {
    }
    @media (min-width: 500px) {
        margin: -120px auto 0;
        .col-md-2 {
            width: 100%;
        }
    }
    @media (min-width: 767px) {
        padding-top: 0px;
        margin: -120px auto 0px;
        width: 95%;
        max-width: 1000px;
        .coupon_wrapper {
            display: flex;
        }
        .row {
            margin: 0;
            margin-bottom: 0;
            display: flex;
        }
        .padding-right-null {
            padding-right: 0;
        }
        .col-md-1 {
            padding: 0px 10px 15px 0;
            margin-bottom: 0;
        }
        .col-md-2 {
            padding: 0px 10px 15px 0;
            margin: 0;
            width: 50%;
        }
        .col-md-3 {
            padding: 0px 10px 15px 0;
            margin: 0;
        }
        .col-md-4 {
            padding: 0px 10px 15px 0;
            margin: 0;
            width: 25%;
        }
    }
    @media (min-width: 900px) {
        margin: -100px auto 0;
    }
    @media (min-width: 1000px) {
        margin: -140px auto 0px;
    }
    @media (min-width: 1200px) {
        margin: -200px auto 0px;
    }
    @media (min-width: 1600px) {
        margin: -200px auto 0;
    }
    @media (min-width: 2050px) {
        margin: -220px auto 0;
    }
    .inputfile {
        width: 50%;
        height: 50px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        cursor: pointer;
    }
    
    .inputfile + label {
        text-align: center;
        border: 2px dashed ${themeGet('colors.inactiveIcon', '#ebebeb')};
        width: 100%;
        font-size: 1rem;
        font-weight: 700;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        padding: 1.625rem 1.25rem;
    }
    
    .no-js .inputfile + label {
        display: none;
    }
    
    .inputfile:focus + label,
    .inputfile.has-focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
    
    .inputfile + label svg {
        width: 1em;
        height: 1em;
        vertical-align: middle;
        fill: currentColor;
        margin-top: -0.25em;
        margin-right: 0.25em;
    }
    
    .inputfile-3:focus + label,
    .inputfile-3.has-focus + label,
    .inputfile-3 + label:hover {
        color: #39757c;
    }

    label {
        display: block;
        color: #767676;
        font-size: 13px !important;
        font-weight: 500;
        margin-bottom: 10px;
        -webkit-transition: 0.2s ease all;
        transition: 0.2s ease all;
    }

    .mbplus {
        margin-bottom: 10px;
    }
    .text-left {
        text-align: left;
        display: block;
        padding-bottom: 10px;
        color: #767676;
        font-size: 13px !important;
        font-weight: 500;
        -webkit-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
        transition: 0.2s ease all;
        .flex {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        p {
            float: right;
        }
        button {
            border-radius: 200px;
            margin: 5px;
            -webkit-transition: 0.1s ease all;
            -webkit-transition: 0.1s ease all;
            transition: 0.1s ease all;
            &.active {
                border: 2px solid ${themeGet('colors.third')};
                height: 18px;
                width: 18px;
                background-color: #fff;
            }
            &.no {
                border: 1px solid #ccc;
                height: 19px;
                width: 19px;
                background-color: #fff;
            }
        }
    }
    .error {
        color: red;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
    }
    .last:last {
        margin-right: 0px;
    }
    .boks {
        p {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
            color: #666;
        }
        h3 {
            color: #999;
            font-weight: 400; 
            font-size: 13px;
            margin: 0;
            margin-bottom: 10px;
        }
        i {
            transition: .3s;
            align-self: center;
            padding: 0 5px 1px;
            margin-right: 15px;
            color: #fff;
            border-radius: 200px;
            border: 1px solid #aaa; 
        }
        cursor: pointer;
        width: 100%;
        padding: 15px;
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #ccc;
        margin-right: 20px;
        margin-bottom: 20px;
        justify-content: end;
        display: flex;
        transition: .3s;
        &:last {
            margin-right: 0px;
        } 
        &:hover {
            border: 1px solid ${themeGet('colors.secondary')};
            p {
                color: #444;
                transition: .3s;
            }
            h3 {
                color: #666;
                transition: .3s;
            }
        }
        &.active {
            cursor: default;
            border: 1px solid ${themeGet('colors.secondary')};
            i {
                border: 1px solid ${themeGet('colors.secondary')};
                background-color:${themeGet('colors.secondary')};
            }
        }
    }


    .file {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
    }

    .price {
        color: #aaa;
        font-size: 12px;
    }

    .price-minus {
        color: #84b574;
        font-size: 12px;
    }

    .right {
        text-align: right;
        float: right;
    }

    .left {
       font-weight: 500; 
    }

    .price_column {
        margin: 15px 0;
        padding: 0 0 15px 0;
        border-bottom: solid 1px #ddd;
    }

    .total {
        color: #555;
    }

    .button_center {
        font-size: '13px';
        font-weight: '700';
        border-radius: '4px';
        padding-left: '15px';
        padding-right: '15px';
        colors: ${secondaryWithBg};
        min-height: 'auto';
        height: '40px';
    }

    .btn {
        background-color: transparent;
        border: 1px solid #bbb;
        border-radius: 0 3px 3px 0;
        font-size: 12px;
        padding: 3px 1px;
        color: #999;
        width: 90px;
        cursor: pointer;
        &.left {
            border-radius: 3px 0 0 3px;
            margin-right: -1px;
            margin-left: calc(100% - 180px);
        }
        &.active {
            border: #393E41 1px solid;
            color: white;
            background-color: #393E41;
        }
    }
`;

const TextWrapper = styled.div`
  width: 100%;
  text-align: center; 
  color: #999;
  a { 
    color: ${props => themeGet('colors.' + props.color)}
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: ${props => themeGet('colors.secondaryHover')}
    }
  }
`;

export { TextWrapper };

export default SignupWrapper