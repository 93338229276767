import React from 'react';
import SEO from '../dataWeb/Seo';
import ErrorSec from '../elementsWeb/Error';
import { ResetCSS } from '../assetsWeb/style/style';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <ResetCSS />
    <ErrorSec></ErrorSec>
  </>
);

export default NotFoundPage;
