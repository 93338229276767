import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from '../../elementsWeb/Image';
import Text from '../../elementsWeb/Text';
import Heading from '../../elementsWeb/Heading';
import Button from '../../elementsWeb/Button';
import Container from '../../elementsWeb/UI/Container';
import GlideCarousel from '../../elementsWeb/GlideCarousel';
import GlideSlide from '../../elementsWeb/GlideCarousel/glideSlide';
import TestimonialSectionWrapper, {
  TextWrapper,
  ImageWrapper,
  RoundWrapper,
  ClientName,
} from './testimonialSection.style';


const TestimonialSection = ({
  btnWrapperStyle,
  commentStyle,
  nameStyle,
  btnStyle,
  designationStyle,
  secTitle,
  secText
}) => {
  // Glide js options
  const glideOptions = {
    type: 'carousel',
    autoplay: false,
    perView: 1,
    hoverpause: true,
    keyboard: true,
    swipeThreshold: false,
    perTouch: 1,
    animationDuration: 700
  };
  const Data = useStaticQuery(graphql`
    query {
      siteJson {
        Testimonials {
          name
          comment
          designation
          avatar_url {
            publicURL
          }
          gradient
          author_url
        }
      }
    }
  `);

  return (
    <TestimonialSectionWrapper id="testimonial_section">
      <Container>
        <Text {...secText} content={'TESTIMONIALS'} />
        <Heading {...secTitle} as="h2" content={'Companies using our APIs'} a={'testimonials'}/>
        <GlideCarousel
          options={glideOptions}
          buttonWrapperStyle={btnWrapperStyle}
          nextButton={
            <Button
              icon={<i className="flaticon-next" />}
              variant="textButton"
              aria-label="next"
              {...btnStyle}
            />
          }
          prevButton={
            <Button
              icon={<i className="flaticon-left-arrow" />}
              variant="textButton"
              aria-label="prev"
              {...btnStyle}
            />
          }>
          <Fragment>
            {Data.siteJson.Testimonials.map((Testimonial, index) => (
              <GlideSlide key={`testimonialts${index}`}>
                <Fragment>
                  <TextWrapper>
                    <i className="flaticon-quotes" />
                    <Text content={Testimonial.comment} {...commentStyle} />
                    <ClientName>
                      <Heading content={Testimonial.name} {...nameStyle} />
                      <Heading
                        content={Testimonial.designation}
                        {...designationStyle}
                      />
                    </ClientName>
                  </TextWrapper>
                  <a href={Testimonial.author_url}>
                    <ImageWrapper>
                      <RoundWrapper gradient={Testimonial.gradient}>
                        <Image
                          src={Testimonial.avatar_url.publicURL}
                          alt="Client Image"
                        />
                      </RoundWrapper>
                    </ImageWrapper>
                  </a>
                </Fragment>
              </GlideSlide>
            ))}
          </Fragment>
        </GlideCarousel>
      </Container>
    </TestimonialSectionWrapper>
  );
};

// TestimonialSection default style
TestimonialSection.defaultProps = {
  commentStyle: {
    color: '#0f2137',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '1.72',
    mb: '47px',
    textAlign: ['center', 'left']
  },
  secTitle: {
    fontSize: ['20px', '24px', '36px', '36px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    mb: ['20px', '25px', '25px', '40px', '60px'],
    textAlign: 'center',
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: 'primary',
    mb: '12px',
  },
  // client name style
  nameStyle: {
    as: 'h3',
    color: '#343d48',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '30px',
    mb: 0,
    textAlign: ['center', 'left']
  },
  // client designation style
  designationStyle: {
    as: 'h5',
    color: 'rgba(52, 61, 72, 0.8)',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '30px',
    mb: 0,
    ml: ['0', '10px'],
    textAlign: ['center', 'left']
  },
  // glide slider nav controls style
  btnWrapperStyle: {
    position: 'absolute',
    bottom: '62px',
    left: '12px',
  },
  // next / prev btn style
  btnStyle: {
    minWidth: 'auto',
    minHeight: 'auto',
    mr: '13px',
    fontSize: '16px',
    color: '#343d484d',
  },
};

export default TestimonialSection;
