import React, {useState} from 'react';
import Text from '../../elementsWeb/Text';
import Heading from '../../elementsWeb/Heading';
import Container from '../../elementsWeb/UI/Container';
import SandboxSectionWrapper from './sandbox.style';
import Video from '../../assetsWeb/images/sandbox-video.mp4';
import Button from '../../elementsWeb/Button';
import ReactPlayer from 'react-player'
import VisibilitySensor from 'react-visibility-sensor';

const SandboxSection = ({
  secTitle,
  secText,
  secDes,
  objectWrapper,
  btnStyle
}) => {

  const [isVisible, setIsVisible] = useState(false);

  return (
    <SandboxSectionWrapper id="testimonial_section">
      <Container>
        <Text {...secText} content={'SANDBOX'}/>
        <Heading {...secTitle} as="h2" content={"Try it out!"} a={'sandbox'}/>
        <Text {...secDes} 
          content={<span>You can try our 3-D Secure Server (3DSS) in a sandbox environment before buying access to the production environment. You get free access to the sandbox environment by signing up to our <a class="inline-link" href="https://dashboard.3dsecure.io/">dashboard</a>. In the dashboard you can manage API keys and see the requests made towards the 3-D secure server. Please check the <a class="inline-link" href="https://docs.3dsecure.io/3dsv2/">documentation</a> on how to make the requests to the 3-D Secure Server. You can sign up for the production environment in the dashboard when you are ready.</span>}/>
        <VisibilitySensor 
          onChange={(isVisible) => setIsVisible(isVisible)}>
            <Container className="col objectWrapper" {...objectWrapper}>
              <ReactPlayer className="player" url={Video} volume={0} muted={true} loop={true} controls={true} playing={isVisible} playsinline width="60%" height="auto" margin="auto"/>
            </Container>
        </VisibilitySensor>
        <a href="https://dashboard.3dsecure.io/">
        <Button
        className="outlined read-more"
        title="Try sandbox for free"
        {...btnStyle}
        />
      </a>
      </Container>
    </SandboxSectionWrapper>
  );
};

// SandboxSection default style
SandboxSection.defaultProps = {
  secTitle: {
    fontSize: ['20px', '24px', '36px', '36px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    mb: ['20px', '25px', '25px', '40px'],
    textAlign: 'center',
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: 'primary',
    mb: '12px',
  },
  secDes: {
    fontSize: '16px',
    color: '#5d646d',
    lineHeight: '1.875',
    mt: '5px',
    ml: 'auto',
    mr: 'auto',
    maxWidth: '900px',
    textAlign: 'center'
  },
  objectWrapper: {
    width: [1, 1, '50%', '50%'],
  },
};

export default SandboxSection;
