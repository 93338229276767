//import node
import React, { useEffect } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { graphql } from 'gatsby';

//Import third party
import { DrawerProvider } from '../assetsWeb/javascript/DrawerContext';

// //CSS
import { saasModernTheme } from '../assetsWeb/global';
import { ResetCSS } from '../assetsWeb/style/style';
import { GlobalStyle, ContentWrapper } from '../assetsWeb/style/sassModern.style';

//import components and containers
import SEO from '../dataWeb/Seo';
import Navbar from '../componentsWeb/Navbar';
import Top from '../componentsWeb/TopSection';
import Footer from '../componentsWeb/Footer';
import BlogSection from '../componentsWeb/BlogSection';

//Analytics
import ReactGA from 'react-ga';

const IndexPage = ({
  data: {
    allMdx: { edges },
  },
}) => {
  useEffect(() => {
    ReactGA.initialize('UA-119334275-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])
  const posts = edges.filter(edge => !!edge.node.frontmatter.date);
  return <ThemeProvider theme={saasModernTheme}>
    <>
      <SEO 
        title={'An easy 3-D Secure feature adds pivotal features to your business'}
        description={'Everything you need to know about our easy sign-up 3-D Secure feature. The easy way to comply with PSD2 while adding state-of-the-art authentication features.'}
      />
      <ResetCSS />
      <GlobalStyle />
      <ContentWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <DrawerProvider>
            <Navbar />
          </DrawerProvider>
        </Sticky>
        <Top title_text={'Blog'} description_text={''}/>
        <BlogSection id={'blog-section'} posts={posts} />
        <Footer />
      </ContentWrapper>
    </>
  </ThemeProvider> 
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMdx(
        sort: { order: ASC, fields: [frontmatter___order] }) {
      edges {
        node {
          id
          body
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            id
            title
            order
          }
        }
      }
    }
  }
`