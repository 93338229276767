//import node
import React, { useEffect } from 'react';
import { graphql } from "gatsby"
import Sticky from 'react-stickynode';
import styled, { ThemeProvider } from 'styled-components';
import { MDXRenderer } from "gatsby-plugin-mdx";

//Import third party
import { DrawerProvider } from '../assetsWeb/javascript/DrawerContext';
import { Modal } from '@redq/reuse-modal'; //So you can open modal on the price site


//CSS
import { saasModernTheme } from '../assetsWeb/global';
import { ResetCSS } from '../assetsWeb/style/style';
import { GlobalStyle, ContentWrapper } from '../assetsWeb/style/sassModern.style';
import '@redq/reuse-modal/es/index.css';


//import components and containers
import SEO from '../dataWeb/Seo';
import Navbar from '../componentsWeb/Navbar';
import Top from '../componentsWeb/TopSection';
import Footer from '../componentsWeb/Footer';

//Analytics
import ReactGA from 'react-ga';

const Wrapper = styled.div`
  width: 80%;
  max-width: 1300px;
  min-width: 300px;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;

  ol, ul {
    padding-inline-start: 40px;
    color: rgb(102, 102, 102);
  }
  ol, ol li {
    list-style-type: decimal;
  }

  ul, ul li {
    list-style-type: disc;
  }
  ol ol, ol ol li {
    list-style-type: lower-alpha;
  }
  .dashed-list ul, .dashed-list ul li {
    list-style-type: none;
  }
  .dashed-list p {
    margin-block-end: 0px;
  }
  .dashed-list li p {
    margin-block-start: 0px;
    margin-block-end: 1em;
  }

  li {
    padding: 0px;
    margin: 2px 5px;
  }

  code {
    padding: 4px 7px 5px;
    border-radius: 4px;
    background-color: #efefef;
  }

  table { 
    border-collapse: collapse;
  }

  td, th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  tr:nth-child(even){
    background-color: #f2f2f2;
  }

  td {
    font-size: 14px;
  }
  
  th {
    font-size: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ccc;
    color: #333;
    font-weight: 500;
  }

  a {
    color: #ea885b;
    cursor: pointer;
  }
  
  a:hover {
    color: #d1754b;
  }
  
  h3 {
    font-size: 28px;
    font-weight: 500;
    color: rgb(68, 68, 68);
    margin: 0 0 15px;
  }
  
  p {
    font-size: 18px;
    color: rgb(102, 102, 102);
    font-weight: 400;
    line-height: 1.4;
  }
`;

export default ({data}) => {
  useEffect(() => {
    ReactGA.initialize('UA-119334275-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])
  const { mdx: post } = data 
  return (
    <ThemeProvider theme={saasModernTheme}>
      <>
        <SEO 
          title={post.frontmatter.seoTitle || post.frontmatter.title || '3dsecure.io - 3-D Secure verifications with VISA & Mastercard'}
          description={post.frontmatter.seoDescription || post.frontmatter.description || 'Use our acquirer agnostic Merchant Plug-in to faciliate 3-D Secure verifications. You only need two simple API requests with our software as a service.'}
        />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Top
            title_text={post.frontmatter.title}
            description_text={post.frontmatter.description}
          />
          <Wrapper>
            <MDXRenderer>{post.body}</MDXRenderer>
          </Wrapper>
          <Footer />
        </ContentWrapper>
      </>
    </ThemeProvider>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { 
      path: { eq: $path }
    }) {
      body
      frontmatter {
        title
        seoTitle
        seoDescription
      }
    }
  }
`

