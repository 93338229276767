const colors = {
  transparent: 'transparent', // 0
  black: '#000000', // 1
  white: '#ffffff', // 2
  headingColor: '#302b4e',
  textColor: '#43414e', // 3
  labelColor: '#767676', // 4
  inactiveField: '#f2f2f2', // 5
  inactiveButton: '#b7dbdd', // 6
  inactiveIcon: '#dddddd', // 7
  primaryLight: '#edf7f7', 
  primary: '#39757c', // 8
  primaryHover: '#2c5b60',
  primaryDark: '#1f464a',  
  secondary: '#ea885b', // 10
  secondaryHover: '#d1754b', // 11
  third: '#393E41', 
  thirdHover: '#1d2021', 
  fourth: '#F7F7F2',
  yellow: '#fdb32a', // 12
  yellowHover: '#F29E02', // 13
  danger: '#dc3545',
  borderColor: '#1b1e25', //14
  primaryBoxShadow: '0 0 15px 0px #ddd',
  secondaryBoxShadow: 'rgba(27,67,111,0.45) 0 5px 10px 0',
  gradient: 'linear-gradient(35deg, #39757c 0%, #39757c 100%)'
};

export default colors;

