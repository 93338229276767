import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing
} from 'styled-system';
import { base, themed } from '../../assetsWeb/global/base';

const HeadingWrapper = styled('p')(
  base,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  themed('Heading'),`
  a {
    color:#15172C;
    &:hover {
      color:#15172C;
    }
  }
  i {
    transition: opacity 0.2s ease-in;
    opacity: 0;
    cursor: pointer;
    margin-left: 20px;
    margin-top: 11px;
    font-size: .5em;
    color: #aaa;
    position: absolute;
  }
  &:hover {
    i {
      opacity: 1;
    }
  }
  &.pt {
    padding-top: 100px;
  }
  `
);

const Heading = ({ content, a, ...props }) => {
  let text = content.split('/n');
  let list = [];
  for(let i = 0; i < text.length; i++) {
    list.push( 
      <div key={`heading-${i}`}>
        {text[i]}
        {a && i === text.length - 1?<i className="flaticon-link"></i> : ''}
      </div>
    )
  } 
  return ( 
  <Fragment>
    <HeadingWrapper id={a} {...props}>{a?<a href={'#' + a}>{list}</a> : list }</HeadingWrapper>
  </Fragment>
)};
export default Heading;

Heading.propTypes = {
  content: PropTypes.string,
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  mt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  mb: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  fontFamily: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  fontWeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  textAlign: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  lineHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  letterSpacing: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  ...base.propTypes
};

Heading.defaultProps = {
  as: 'h1',
  mt: 0,
  mb: '1rem',
  fontWeight: 'bold'
};
