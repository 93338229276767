import Fade from 'react-reveal/Fade';
import Image from "../../../../src/elementsWeb/Image";
import ScaExemptions1 from "../../../../src/assetsWeb/images/sca_exemptions1.png";
import ScaExemptions2 from "../../../../src/assetsWeb/images/sca_exemptions2.png";
import ScaExemptions3 from "../../../../src/assetsWeb/images/sca_exemptions3.png";
import ScaExemptions4 from "../../../../src/assetsWeb/images/sca_exemptions4.png";
import * as React from 'react';
export default {
  Fade,
  Image,
  ScaExemptions1,
  ScaExemptions2,
  ScaExemptions3,
  ScaExemptions4,
  React
};