const button = {
    type: 'button',
    fontSize: '16px',
    fontWeight: '500',
    borderRadius: '4px',
    pl: '30px',
    pr: '30px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    height: '60px',
    ml: '20px',
    mb: '20px',
    transform: 'translateX(-50%)',
    width: 'calc(100% - 40px)',
};

const button_2 = {
    type: 'button',
    fontSize: '11px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '20px',
    pr: '20px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    height: '50px',
    ml: '30%',
    transform: 'translateX(-50%)',
    mt: '10px',
    width: '40%',
};


export {button, button_2}