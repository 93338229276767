import styled from 'styled-components';

const NotifyWrapper = styled.div`
    background-color: white;
    border-left: 3px solid ${props => props.color};
    color: #777;
    display: flex;
    box-shadow: 0 0 8px -4px #999;
    font-size: 15px;
    font-weitgh: 400;
    padding: 26px 30px;
    position: fixed;
    border-radius: 4px;
    right: 16px;
    bottom: ${props => props.bottom}px;
    z-index: 5909090;
    transition: bottom 0.5s ease;
    align-items: center;
    margin-left: 16px;
    h3 {
        margin: 0;
    }
    .icon {
        border-radius: 200px;
        padding: 7.5px 7.5px 0;
        background-color: ${props => props.color};
        color: white;
        height: 39px;
        margin-right: 30px;
        svg {
            margin: 0;
        }
    }
    .exit {
        position: absolute;
        align-self: flex-start;
        right: 10px;
        top: 10px;
        transition: .3s;
        cursor: pointer;
        &:hover {
            color: #333;
        }
    }
`; 

export default NotifyWrapper;