import React from "react"
import SignupWrapper, { TextWrapper } from "./signupSection.style"
import Box from "../../elementsWeb/Box"
import Heading from "../../elementsWeb/Heading"
import Text from "../../elementsWeb/Text"
import Input from "../../elementsWeb/Input"
import Button from "../../elementsWeb/Button"
import Select from "../../elementsWeb/Select"
import axios from "axios"
import Notifybox, { notify } from "../Notify"
import { Check, AlertCircle, X } from "react-feather"
import Icon from "react-icons-kit"
import { checkmark } from "react-icons-kit/icomoon/checkmark"
import { button, button_2 } from "./button.style"

class Signup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        name: "",
        email: "",
        phone: "",
        company_name: "",
        website: "",
        street_address: "",
        zip_code: "",
        city: "",
        country: "",
        country_code: "",
        subscription_handle: "",
        pgp_key: "",
        aoc_documentation: null,
        token: "",
      },
      errors: {
        name: "",
        email: "",
        phone: "",
        company_name: "",
        website: "",
        street_address: "",
        city: "",
        zip_code: "",
        pgp_key: "",
        country: "",
        aoc_documentation: "",
        subscription: "",
        coupon_error_handle: "",
      },
      coupon_code: "",
      coupon_code_before: "",
      fileName: "",
      subscriptions: [],
      price: {
        setup_fee: 0,
        amount: 0,
        vat: 0,
        percentage: 0,
        fixed_amount: 0,
        discount: 0,
        total: 0,
      },
      toggleInfo: false,
      interval: 1,
    }

    this.eventhandle = this.eventhandle.bind(this)
    this.countryhandle = this.countryhandle.bind(this)
    this.subscriptionhandle = this.subscriptionhandle.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.filehandle = this.filehandle.bind(this)
    this.getSubscriptionPlan = this.getSubscriptionPlan.bind(this)
    this.configureReepay = this.configureReepay.bind(this)
    this.load = this.load.bind(this)
    this.couponhandle = this.couponhandle.bind(this)
    this.sendForm = this.sendForm.bind(this)
    this.redeemCode = this.redeemCode.bind(this)
    this.couponRequest = this.couponRequest.bind(this)
    this.checkAllFields = this.checkAllFields.bind(this)
    this.saveInfo = this.saveInfo.bind(this)
  }

  componentWillMount = () => {
    this.load("https://token.reepay.com/token.js")
    this.getSubscriptionPlan()
  }

  load = url => {
    return new Promise(function(resolve, reject) {
      var script = document.createElement("script")
      script.type = "text/javascript"
      script.async = true
      script.src = url
      script.onload = resolve
      script.onerror = reject
      document.head.appendChild(script)
    })
  }

  saveInfo = () => {
    this.setState({
      toggleInfo: !this.state.toggleInfo,
    })
  }

  getSubscriptionPlan = () => {
    const aws = axios.create({
      baseURL: process.env.GATSBY_AWS_URL,
    })
    aws.defaults.headers.post["Content-Type"] = "application/json"
    aws.defaults.headers.common["X-Api-Key"] = process.env.GATSBY_API_KEY
    aws.get("/plans").then(res => {
      this.setState(
        {
          subscriptions: res.data.filter(e => e.version === 1),
        },
        () => {
          if (
            localStorage.getItem("subscription") &&
            localStorage.getItem("subscription") !== "undefined"
          ) {
            let local = localStorage.getItem("subscription")
            let interval = localStorage.getItem("interval")
            let sub = {
              value: local,
            }
            this.setState({
              interval: interval,
            })
            this.subscriptionhandle(sub)
          }
        }
      )
    })
  }

  couponRequest = (code, plan) => {
    const coupon_elm = {
      coupon_code: code,
      plan: plan,
    }
    const aws = axios.create({ baseURL: process.env.GATSBY_AWS_URL })
    aws.defaults.headers.post["Content-Type"] = "application/json"
    aws.defaults.headers.common["X-Api-Key"] = process.env.GATSBY_API_KEY
    return aws.post("/coupons", JSON.stringify(coupon_elm))
  }

  redeemCode = () => {
    if (this.state.form.subscription_handle) {
      notify(
        "Processing...",
        `Please wait a few seconds while we process your discount code.`,
        "#17a2b8",
        5000,
        <AlertCircle />
      )
      this.couponRequest(
        this.state.coupon_code_before,
        this.state.form.subscription_handle
      )
        .then(res => {
          if (res.data.couponData.handle) {
            let price = {}
            let total = this.state.price.total
            let discount = ""
            let coupon_error_handle = ""
            let coupon_code = this.state.coupon_code_before
            if (res.data.discountData.percentage) {
              let percentage = res.data.discountData.percentage
              if (res.data.discountData.apply_to.includes("all")) {
                coupon_error_handle = "Coupon code is not valid"
                coupon_code = ""
                percentage = ""
                notify(
                  "Failed",
                  "Something went wrong.",
                  "#dc3545",
                  5000,
                  <X />
                )
              } else if (
                res.data.discountData.apply_to.includes("setup_fee" && "plan")
              ) {
                discount =
                  ((this.state.price.amount + this.state.price.setup_fee) /
                    100) *
                  (res.data.discountData.percentage / 100)
                total =
                  (this.state.price.amount + this.state.price.setup_fee) / 100 -
                  discount
                notify(
                  "Discount",
                  `You have succesfully redeemed your code :-)`,
                  "#28a745",
                  5000,
                  <Check />
                )
              } else if (res.data.discountData.apply_to.includes("plan")) {
                discount =
                  (this.state.price.amount / 100) *
                  (res.data.discountData.percentage / 100)
                total =
                  (this.state.price.amount + this.state.price.setup_fee) / 100 -
                  discount
                notify(
                  "Discount",
                  `You have succesfully redeemed your code :-)`,
                  "#28a745",
                  5000,
                  <Check />
                )
              } else if (res.data.discountData.apply_to.includes("setup_fee")) {
                discount =
                  (this.state.price.setup_fee / 100) *
                  (res.data.discountData.percentage / 100)
                total =
                  (this.state.price.amount + this.state.price.setup_fee) / 100 -
                  discount
                notify(
                  "Discount",
                  `You have succesfully redeemed your code :-)`,
                  "#28a745",
                  5000,
                  <Check />
                )
              } else {
                coupon_error_handle = "Coupon code is not valid"
                coupon_code = ""
                percentage = ""
                notify(
                  "Failed",
                  "Something went wrong, please refresh page.",
                  "#dc3545",
                  5000,
                  <X />
                )
              }
              price = {
                ...this.state.price,
                percentage,
                fixed_amount: 0,
                total,
                discount,
              }
            }
            if (res.data.discountData.amount) {
              let fixed_amount = res.data.discountData.amount
              if (res.data.discountData.apply_to.includes("all")) {
                notify(
                  "Failed",
                  "Something went wrong, please refresh page.",
                  "#dc3545",
                  5000,
                  <X />
                )
                coupon_error_handle = "Coupon code is not valid"
                coupon_code = ""
                fixed_amount = ""
              } else if (
                res.data.discountData.apply_to.includes("setup_fee" && "plan")
              ) {
                discount = res.data.discountData.amount / 100
                total =
                  (this.state.price.amount + this.state.price.setup_fee) / 100 -
                  discount
                if (total < 0) {
                  notify(
                    "Failed",
                    "Something went wrong, please refresh page.",
                    "#dc3545",
                    5000,
                    <X />
                  )
                  coupon_code = ""
                  coupon_error_handle = "Coupon code is not valid"
                  fixed_amount = ""
                } else {
                  notify(
                    "Discount",
                    `You have succesfully redeemed your code :-)`,
                    "#28a745",
                    5000,
                    <Check />
                  )
                }
              } else if (res.data.discountData.apply_to.includes("plan")) {
                discount = res.data.discountData.amount / 100
                total =
                  this.state.price.amount / 100 -
                  discount +
                  this.state.price.setup_fee / 100
                if (total < 0) {
                  notify(
                    "Failed",
                    "Something went wrong, please refresh page.",
                    "#dc3545",
                    5000,
                    <X />
                  )
                  coupon_code = ""
                  coupon_error_handle = "Coupon code is not valid"
                  fixed_amount = ""
                } else {
                  notify(
                    "Discount",
                    `You have succesfully redeemed your code :-)`,
                    "#28a745",
                    5000,
                    <Check />
                  )
                }
              } else if (res.data.discountData.apply_to.includes("setup_fee")) {
                discount = res.data.discountData.amount / 100
                total =
                  this.state.price.setup_fee / 100 -
                  discount +
                  this.state.price.amount / 100
                if (total < 0) {
                  notify(
                    "Failed",
                    "Something went wrong, please refresh page.",
                    "#dc3545",
                    5000,
                    <X />
                  )
                  coupon_code = ""
                  coupon_error_handle = "Coupon code is not valid"
                  fixed_amount = ""
                } else {
                  notify(
                    "Discount",
                    `You have succesfully redeemed your code :-)`,
                    "#28a745",
                    5000,
                    <Check />
                  )
                }
              } else {
                notify(
                  "Failed",
                  "Something went wrong, please refresh page.",
                  "#dc3545",
                  5000,
                  <X />
                )
                coupon_error_handle = "Coupon code is not valid"
                coupon_code = ""
                fixed_amount = ""
              }
              price = {
                ...this.state.price,
                percentage: 0,
                fixed_amount,
                total: total,
                discount: discount,
              }
            }
            this.setState({
              price,
              errors: {
                ...this.state.errors,
                subscription: "",
                coupon_error_handle,
              },
              coupon_code,
            })
          }
        })
        .catch(error => {
          let status = error.response.status
          let coupon_error_handle = ""
          if (this.state.coupon_code === "") {
            coupon_error_handle = "You have to write a discount code."
          }
          if (status === 400 && this.state.coupon_code !== "") {
            coupon_error_handle = "You have to write a valid discount code."
          }
          if (status === 404) {
            coupon_error_handle = "The discount code was not found."
          }
          if (
            status === 500 ||
            status === 501 ||
            status === 502 ||
            status === 503 ||
            status === 504
          ) {
            coupon_error_handle =
              "The server is not responding, try again later."
          }
          this.setState({
            errors: {
              ...this.state.errors,
              coupon_error_handle,
            },
          })
          notify("Failed", coupon_error_handle, "#dc3545", 5000, <X />)
        })
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          subscription: "You need to pick a subscription first.",
        },
      })
    }
  }

  configureReepay = funct => {
    // check if the reepaytoken is undefined so it does not block our site from loading
    if (typeof reepaytoken === "undefined") {
      return undefined
    }

    let settoken = token => {
      this.setState({
        form: {
          ...this.state.form,
          token: token,
        },
      })
    }
    // reppay is defined in an external script, so make the linter ignore next line.
    // eslint-disable-next-line
    return reepaytoken.configure({
      key: "pub_968ba1da4efaf76f1c8513f6679ace33",
      language: "en",
      recurring: true,
      token: function(result) {
        // token received
        settoken(result.token)
        funct()
      },
      ready: function() {
        // Reepay ready
      },
      close: function() {
        // Modal closed
        //this.sendToHubspot();
        document.getElementById("reepay-token-iframe").remove()
        notify(
          "Payment information needed",
          `You need to provide your payment information to complete your registration.`,
          "#ffc107",
          5000,
          <AlertCircle />
        )
      },
    })
  }

  eventhandle = event => {
    let { name, value } = event.target
    let errors = this.state.errors
    // eslint-disable-next-line
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    )
    const validPGPRegex = RegExp(
      /-----BEGIN PGP PUBLIC KEY-----(.*)-----END PGP PUBLIC KEY-----/s
    )
    const validPGPRegexBlock = RegExp(
      /-----BEGIN PGP PUBLIC KEY BLOCK-----(.*)-----END PGP PUBLIC KEY BLOCK-----/s
    )
    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Must be valid."
        break
      case "phone":
        errors.phone = value.length <= 2 ? "Must be valid." : ""
        break
      case "name":
        errors.name = value.split(/ (.+)/).length < 2 ? "Must be valid." : ""
        break
      case "company_name":
        errors.company_name = value.length < 1 ? "Must be valid." : ""
        break
      case "website":
        errors.website = value.length < 1 ? "Must be valid." : ""
        break
      case "street_address":
        errors.street_address = value.length < 1 ? "Must be valid." : ""
        break
      case "city":
        errors.city = value.length < 1 ? "Must be valid." : ""
        break
      case "pgp_key":
        errors.pgp_key =
          validPGPRegex.test(value) || validPGPRegexBlock.test(value)
            ? ""
            : "Must be valid."
        break
      case "zip_code":
        errors.zip_code = value.length < 1 ? "Must be valid." : ""
        break
      default:
        break
    }
    this.setState({
      errors,
      form: {
        ...this.state.form,
        [name]: value,
      },
    })
  }

  countryhandle = event => {
    this.setState({
      form: {
        ...this.state.form,
        country: event.label,
        country_code: event.value,
      },
      errors: {
        ...this.state.errors,
        country: "",
      },
    })
  }

  subscriptionhandle = event => {
    let sub = this.state.subscriptions.find(e => e.handle === event.value)
    if (sub)
      this.setState({
        coupon_code: "",
        form: {
          ...this.state.form,
          subscription_handle: sub.handle,
        },
        price: {
          ...this.state.price,
          setup_fee: sub.setup_fee,
          amount: sub.amount,
          vat: sub.vat,
          total: (sub.setup_fee + sub.amount) / 100,
          percentage: 0,
          fixed_amount: 0,
          discount: 0,
        },
        errors: {
          ...this.state.errors,
          subscription: "",
        },
      })
  }

  filehandle = event => {
    const file = event.target.files[0]
    if (file) {
      this.setState({
        fileName: `${file.name} (${file.size / 1000000} MB)`,
      })
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        let err = ""
        if (file.size / 1000000 > 7.81) {
          err = "The file upload size must be less than 7.81 MB."
        } else if (file.name.substring(file.name.length - 3) !== "pdf") {
          if (file.name.substring(file.name.length - 3) === "PDF") {
            err = ""
          } else {
            err = "File must be a pdf"
          }
        }
        this.setState({
          form: {
            ...this.state.form,
            aoc_documentation: reader.result.split(",").pop(),
          },
          errors: {
            ...this.state.errors,
            aoc_documentation: err,
          },
        })
      }
      reader.onerror = error => {}
    }
  }

  couponhandle = event => {
    let { value } = event.target
    this.setState({
      coupon_code_before: value,
    })
  }

  checkAllFields = () => {
    let errors = {}
    let coupon_code = ""
    let isRunning = false
    const reepay = this.configureReepay(this.onSubmit)
    let form = this.refs.signupform
    for (let i = 0; i < form.length; i++) {
      let errorname = form[i].name
      if (
        errorname &&
        form[i].type !== "file" &&
        errorname !== "coupon" &&
        form[i].name !== "reepay-token"
      ) {
        // eslint-disable-next-line
        const validEmailRegex = RegExp(
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        )
        if (errorname === "email" && !validEmailRegex.test(form[i].value)) {
          errors = {
            ...errors,
            [errorname]: "The email field must be a valid email.",
          }
        } else if (form[i].value === "") {
          errors = {
            ...errors,
            [errorname]: "This field is required.",
          }
        }
      }
    }
    if (this.state.form.country) {
      if (this.state.form.aoc_documentation) {
        if (this.state.form.subscription_handle) {
          if (this.state.coupon_code_before && this.state.coupon_code === "") {
            this.couponRequest(
              this.state.coupon_code_before,
              this.state.form.subscription_handle
            )
              .then(res => {
                coupon_code = res.data.couponData.handle
                  ? this.state.coupon_code_before
                  : ""
                this.setState({ coupon_code })
                isRunning = true
              })
              .catch(error => {
                let status = error.response.status
                let coupon_error_handle = ""
                if (status === 400 && this.state.coupon_code !== "") {
                  coupon_error_handle =
                    "You have to write a valid discount code."
                }
                if (status === 404) {
                  coupon_error_handle = "The discount code was not found."
                }
                const server_error = [500, 501, 502, 503, 504]
                if (server_error.includes(status)) {
                  coupon_error_handle =
                    "The server is not responding, try again later."
                }
                errors = {
                  ...errors,
                  coupon_error_handle,
                }
                this.setState({
                  errors,
                })
                notify("Failed", coupon_error_handle, "#dc3545", 5000, <X />)
              })
          } else {
            isRunning = true
          }
        } else {
          errors = {
            ...errors,
            subscription: "Please select a subscription.",
          }
        }
      } else {
        errors = {
          ...errors,
          aoc_documentation: "Please upload your Attestation of Compliance.",
        }
      }
    } else {
      errors = {
        ...errors,
        country: "This field is required",
      }
    }
    this.setState(
      {
        coupon_code: this.state.coupon_code
          ? this.state.coupon_code
          : coupon_code,
        errors: errors,
      },
      () => {
        if (Object.entries(this.state.errors).length < 1) {
          const interval = setInterval(function() {
            if (isRunning) {
              reepay.open()
              clearInterval(interval)
            } else if (Object.entries(errors).length > 0) {
              clearInterval(interval)
            }
          }, 1000)
        } else {
          notify(
            "Validation failed",
            `The form was not valid, please fill out all required fields.`,
            "#dc3545",
            5000,
            <X />
          )
        }
      }
    )
  }

  onSubmit = () => {
    this.sendForm(this.state.form)
    notify(
      "Processing...",
      `Please wait a few seconds while we process your request.`,
      "#17a2b8",
      500000,
      <AlertCircle />
    )
  }

  sendForm = form => {
    const names = form.name.split(/ (.+)/)
    let subscribing_customer_data = {
      plan: form.subscription_handle,
      source: form.token,
      generate_handle: true,
      signup_method: "source",
      create_customer: {
        email: form.email,
        address: form.street_address,
        city: form.city,
        country: form.country_code,
        phone: form.phone,
        postal_code: form.zip_code,
        first_name: names[0],
        last_name: names[1],
        generate_handle: true,
      },
    }
    if (this.state.coupon_code) {
      subscribing_customer_data = {
        ...subscribing_customer_data,
        coupon_codes: [this.state.coupon_code],
      }
    }
    const aws = axios.create({
      baseURL: process.env.GATSBY_AWS_URL,
    })
    aws.defaults.headers.post["Content-Type"] = "application/json"
    aws.defaults.headers.common["X-Api-Key"] = process.env.GATSBY_API_KEY
    aws
      .post("/subscriptions", JSON.stringify(subscribing_customer_data))
      .then(response => {
        // handle successfull response
        const customer_note_data = {
          note:
            "Name: " +
            form.name +
            "\nEmail: " +
            form.email +
            "\nPhone: " +
            form.phone +
            "\nCompany name: " +
            form.company_name +
            "\nWebsite: " +
            form.website +
            "\nAddress: " +
            form.street_address +
            ", " +
            form.zip_code +
            " " +
            form.city +
            ", " +
            form.country,
          customer: response.data.customer,
        }
        let awspost = [
          aws.post("/signups", JSON.stringify(form)),
          aws.post("/notes", JSON.stringify(customer_note_data)),
        ]
        if (form.pgp_key.length > 4096) {
          let customer_pgp_note = []
          let pgp = form.pgp_key
          let pgp_length = 4065
          let times = Math.ceil(pgp.length / pgp_length) - 1
          const title = (count, times) => {
            let fixed_count = count < 9 ? "0" + (count + 1) : count + 1
            let fixed_times = times < 9 ? "0" + (times + 1) : times + 1
            return (
              "PGP PUBLIC KEY, PART " + fixed_count + " OF " + fixed_times + ":"
            )
          }
          for (let i = 0; i <= times; i++) {
            customer_pgp_note.push({
              note:
                title(i, times) +
                pgp.substring(i * pgp_length, (i + 1) * pgp_length),
              customer: response.data.customer,
            })
            awspost.push(
              aws.post("/notes", JSON.stringify(customer_pgp_note[i]))
            )
          }
        } else {
          let customer_pgp_note = {
            note: form.pgp_key,
            customer: response.data.customer,
          }
          awspost.push(aws.post("/notes", JSON.stringify(customer_pgp_note)))
        }
        return axios.all(awspost)
      })
      .then(
        axios.spread(() => {
          // handle successfull responses
          notify(
            "Registration Completed",
            `Thank you :-) We have sent you an e-mail with all the details! We will sent you an API key as quickly as possible.`,
            "#28a745",
            5000,
            <Check />
          )
        })
      )
      .catch(() => {
        notify(
          "Registration failed",
          `Sorry, but something went wrong during the registration. Please sent us an e-mail. Thank you :-)`,
          "#dc3545",
          5000,
          <X />
        )
      })
  }

  render() {
    // Change to true if you want to have annual prices again
    let yearly_price = false

    //Grab all cities
    const countries = this.props.countries

    //Grab all price elm's
    const price = this.state.price

    //Grab all subscription elm
    let subscriptions = []
    let sub_elm = []
    let subs = this.state.subscriptions.sort(function(a, b) {
      return a.amount - b.amount
    })
    let interval = parseInt(this.state.interval)
    //Catch the active subscription
    let subscribtion = this.state.form.subscription_handle
      ? subs.find(elm => elm.handle === this.state.form.subscription_handle)
      : []
    //Make the subscription elm both for monthly and yearly
    for (let i = 0; i < subs.length; i++) {
      subscriptions.push({ value: subs[i].handle, label: subs[i].name })
      if (subs[i].interval_length === interval) {
        sub_elm.push(
          <div
            key={`subscription-elm${i}`}
            className={
              this.state.form.subscription_handle === subs[i].handle
                ? "boks active"
                : "boks"
            }
            onClick={() =>
              this.subscriptionhandle({
                value: subs[i].handle,
                label: subs[i].name,
              })
            }
          >
            <Icon icon={checkmark} className="price_list_icon" size={10} />
            <div>
              <h3>{subs[i].name}</h3>
              <p>
                {subs[i].interval_length === 1
                  ? subs[i].amount / 100 + " € / month"
                  : subs[i].interval_length === 12
                  ? subs[i].amount / 100 + " € / year"
                  : subs[i].amount / 100 +
                    " € / every " +
                    subs[i].interval_length +
                    " months"}
              </p>
            </div>
          </div>
        )
      }
    }
    return (
      <SignupWrapper color="third">
        <form onSubmit={() => this.onSubmit} ref="signupform">
          <input type="hidden" id="token" name="reepay-token" />
          <Box className="row">
            <Box className="col-md-1">
              <Heading content="Sign up" className="title" />
              <Text
                content="Our solution is a single subscription covering both 3-D Secure 1 and 3-D Secure 2 product."
                className="desc"
              />
            </Box>
          </Box>
          <Box className="flex_box">
            <Box className="row_heading">
              <Box className="col-md-1">
                <Heading
                  as="h2"
                  content="Contact person"
                  className="signuptext"
                />
                <Text
                  content="We will use this email for all communication e.g. receipts, info etc."
                  className="sub_text"
                />
              </Box>
            </Box>

            <Box className="row_content">
              <Box className="row">
                <Box className="col-md-1 fixed_width">
                  <Input
                    inputType="text"
                    isMaterial
                    label="Full name"
                    name="name"
                    eventhandle={this.eventhandle}
                  />
                  <p className="error">{this.state.errors.name}</p>
                </Box>
              </Box>

              <Box className="row">
                <Box className="col-md-1 fixed_width">
                  <Input
                    inputType="text"
                    isMaterial
                    label="E-mail"
                    name="email"
                    eventhandle={this.eventhandle}
                  />
                  <p className="error">{this.state.errors.email}</p>
                </Box>
              </Box>
              <Box className="row">
                <Box className="col-md-1 fixed_width">
                  <Input
                    inputType="number"
                    isMaterial
                    label="Phone"
                    name="phone"
                    eventhandle={this.eventhandle}
                  />
                  <p className="error">{this.state.errors.phone}</p>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="flex_box">
            <Box className="row_heading">
              <Box className="col-md-1">
                <Heading
                  as="h2"
                  content="Company information"
                  className="signuptext"
                />
              </Box>
            </Box>

            <Box className="row_content">
              <Box className="row">
                <Box className="col-md-1 fixed_width">
                  <Input
                    inputType="text"
                    isMaterial
                    label="Company Name"
                    name="company_name"
                    eventhandle={this.eventhandle}
                  />
                  <p className="error">{this.state.errors.company_name}</p>
                </Box>
              </Box>

              <Box className="row">
                <Box className="col-md-1 fixed_width">
                  <Input
                    inputType="text"
                    isMaterial
                    label="Street Address"
                    name="street_address"
                    eventhandle={this.eventhandle}
                  />
                  <p className="error">{this.state.errors.street_address}</p>
                </Box>
              </Box>

              <Box className="row">
                <Box className="col-md-2 padding-right-plus">
                  <Input
                    inputType="number"
                    isMaterial
                    label="Zip Code"
                    name="zip_code"
                    eventhandle={this.eventhandle}
                  />
                  <p className="error">{this.state.errors.zip_code}</p>
                </Box>
                <Box className="col-md-2 padding-right-null">
                  <Input
                    inputType="text"
                    isMaterial
                    label="City"
                    name="city"
                    eventhandle={this.eventhandle}
                  />
                  <p className="error">{this.state.errors.city}</p>
                </Box>
              </Box>

              <Box className="row">
                <Box className="col-md-2">
                  <Box className="col-md-1">
                    <Select
                      options={countries}
                      placeholder="Select country"
                      className="domain_search_select"
                      aria-label="select options"
                      eventhandle={this.countryhandle}
                    />
                    <p className="error">{this.state.errors.country}</p>
                  </Box>
                  <Box className="col-md-1">
                    <Input
                      inputType="text"
                      isMaterial
                      label="Website"
                      name="website"
                      eventhandle={this.eventhandle}
                    />
                    <p className="error">{this.state.errors.website}</p>
                  </Box>
                  <Box className="col-md-1">
                    <label>
                      AoC — Attestation of Compliance (PDF of max 8 MB)
                    </label>
                    <input
                      type="file"
                      name="aoc_documentation"
                      id="file-3"
                      className="inputfile inputfile-3"
                      onChange={this.filehandle}
                    />
                    <label>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="17"
                        viewBox="0 0 20 17"
                      >
                        <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                      </svg>
                      <span>
                        {this.state.fileName
                          ? this.state.fileName
                          : "Choose a file"}
                        &hellip;
                      </span>
                    </label>
                    <p className="error">
                      {this.state.errors.aoc_documentation}
                    </p>
                  </Box>
                </Box>
                <Box className="col-md-2 padding-right-null">
                  <Input
                    inputType="textarea"
                    isMaterial
                    label="PGP Public key"
                    name="pgp_key"
                    eventhandle={this.eventhandle}
                  />
                  <p className="error">{this.state.errors.pgp_key}</p>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="flex_box">
            <Box className="row_heading">
              <Box className="col-md-1">
                <Heading as="h2" content="Payment" className="signuptext" />
              </Box>
            </Box>

            <Box className="row_content">
              {subs.length <= 7 ? (
                <Box>
                  <Box className="row mb">
                    <Box className="col-md-2">
                      <label>Subscription plan</label>
                    </Box>
                    {yearly_price ? (
                      <Box className="col-md-2 padding-right-plus">
                        <button
                          type="button"
                          onClick={() => this.setState({ interval: 1 })}
                          className={
                            parseInt(this.state.interval) === 1
                              ? "active left btn"
                              : "btn left"
                          }
                        >
                          Monthly plan
                        </button>
                        <button
                          type="button"
                          onClick={() => this.setState({ interval: 12 })}
                          className={
                            parseInt(this.state.interval) === 12
                              ? "active btn"
                              : "btn"
                          }
                        >
                          Annual plan
                        </button>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box className="row last">{sub_elm}</Box>
                  <Box className="row mbplus">
                    <p className="error">{this.state.errors.subscription}</p>
                  </Box>
                </Box>
              ) : (
                <Box className="col-md-1 fixed_width">
                  <Select
                    options={subscriptions}
                    placeholder="Select subscription"
                    className="domain_search_select"
                    aria-label="select options"
                    eventhandle={this.subscriptionhandle}
                  />
                  <p className="error">{this.state.errors.subscription}</p>
                </Box>
              )}
              <Box className="row">
                <Box className="coupon_wrapper fixed_width">
                  <Box className="coupon_input">
                    <Input
                      inputType="text"
                      isMaterial
                      label="Coupon code"
                      name="coupon"
                      eventhandle={this.couponhandle}
                    />
                    <p className={"error"}>
                      {this.state.errors.coupon_error_handle}
                    </p>
                  </Box>
                  <Box className="coupon_button padding-right-null">
                    <Button
                      {...button_2}
                      title="Redeem code"
                      type="button"
                      onClick={() => this.redeemCode()}
                    />
                  </Box>
                </Box>
              </Box>

              <Box className="price_section">
                <p className="price_column">
                  <span className="left price">
                    {subscribtion.interval_length === 1
                      ? "Price per month"
                      : subscribtion.interval_length === 12
                      ? "Price per year"
                      : "Price"}
                  </span>
                  <span className="right price">{price.amount / 100} €</span>
                </p>
                <p className="price_column">
                  <span className="left price">One-time setup fee</span>
                  <span className="right price">{price.setup_fee / 100} €</span>
                </p>
                {price.vat ? (
                  <p className="price_column">
                    <span className="left price">VAT.</span>
                    <span className="right price">{price.vat * 100} %</span>
                  </p>
                ) : (
                  ""
                )}
                {price.percentage === 0 ? (
                  ""
                ) : (
                  <p className="price_column">
                    <span className="left price-minus">
                      Discount {price.percentage}%
                    </span>
                    <span className="right price-minus">
                      {" "}
                      - {parseFloat(price.discount).toFixed(2)} €
                    </span>
                  </p>
                )}
                {price.fixed_amount === 0 ? (
                  ""
                ) : (
                  <p className="price_column">
                    <span className="left price-minus">Discount </span>
                    <span className="right price-minus">
                      {" "}
                      - {parseFloat(price.discount).toFixed(2)} €
                    </span>
                  </p>
                )}
                <p className="price_column">
                  <span className="left total">Total</span>
                  <span className="right total">
                    {parseFloat(price.total).toFixed(2)} €
                  </span>
                </p>
              </Box>
            </Box>
          </Box>
          <TextWrapper color={"secondary"}>
            <Text
              content={
                <span>
                  When signing up you agree to our{" "}
                  <a target="_blank" rel="noopener noreferrer" href="/tos/">
                    Terms of service
                  </a>{" "}
                  and{" "}
                  <a target="_blank" rel="noopener noreferrer" href="/dpa/">
                    Data Processing Agreement
                  </a>
                  .
                </span>
              }
            />
          </TextWrapper>
          <Button
            {...button}
            title="Sign up"
            type="button"
            onClick={() => this.checkAllFields()}
          />
        </form>
        <Notifybox />
      </SignupWrapper>
    )
  }
}

export default Signup
