import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";

class Posts extends React.Component {
      render() {
            return (
                  <>{this.props.postItems.map((post) => (
                        <div key={post.node.frontmatter.id}  id={post.node.frontmatter.id} className="mt-70">
                              <MDXRenderer className="mt-70" id={post.node.frontmatter.id} >{post.node.body}</MDXRenderer>
                        </div>
                  ))}</>
            )
      };
}
export default Posts