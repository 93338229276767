import React from 'react';
import ShapeWrapper from './tiltShape.style';

const TiltShape = ({ className, color }) => {
  // Add all classs to an array
  const addAllClasses = ['tilt_shape'];
  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <ShapeWrapper className={addAllClasses.join(' ')}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1800 280.48">
        <g id="Lag_2" data-name="Lag 2">
          <g id="Lag_1-2" data-name="Lag 1">
            <g className="cls-1" style={{opacity: 0.75}}>
              <polygon className="cls-2" style={{fill: '#fff'}} points="1800 0 1800 36.08 13.15 275.16 1800 0"/>
            </g>
            <polygon className="cls-2" style={{fill: '#fff'}} points="1800 36.08 1800 280.48 0 280.48 0 276.93 1.27 276.75 13.15 275.16 1800 36.08"/>
          </g>
        </g>
      </svg>
    </ShapeWrapper>
  );
};

export default TiltShape;
