import Fade from 'react-reveal/Fade';
import Image from "../../../../src/elementsWeb/Image";
import Benefits1 from "../../../../src/assetsWeb/images/3ds_benefits1.png";
import Benefits2 from "../../../../src/assetsWeb/images/3ds_benefits2.png";
import Benefits3 from "../../../../src/assetsWeb/images/3ds_benefits3.png";
import Benefits4 from "../../../../src/assetsWeb/images/3ds_benefits4.png";
import Benefits5 from "../../../../src/assetsWeb/images/3ds_benefits5.png";
import ContextData1 from "../../../../src/assetsWeb/images/contextual_data1.png";
import ContextData2 from "../../../../src/assetsWeb/images/contextual_data2.png";
import ContextData3 from "../../../../src/assetsWeb/images/contextual_data3.png";
import ContextData4 from "../../../../src/assetsWeb/images/contextual_data4.png";
import ContextData5 from "../../../../src/assetsWeb/images/contextual_data5.png";
import * as React from 'react';
export default {
  Fade,
  Image,
  Benefits1,
  Benefits2,
  Benefits3,
  Benefits4,
  Benefits5,
  ContextData1,
  ContextData2,
  ContextData3,
  ContextData4,
  ContextData5,
  React
};