import styled from 'styled-components';
import BannerBG from '../../assetsWeb/images/banner-texture.png';
import { themeGet } from 'styled-system';

export const FooterWrapper = styled.footer`
  margin-top: 0px;
  overflow: hidden;
  z-index: -1;
  background-image: url(${BannerBG}), ${themeGet('colors.gradient', 'linear-gradient(35deg, #44a1a0 0%, #247b7b 100%)')};
  padding: 0 0 30px 0;
  width: 101%;;
  color: #fff;
  .footer {
    padding: 0 40px 0 30px
  }
  .tilt_shape {
    position: relative;
    margin-top: -1px;
  }
  i {
    align-self: flex-start;
    width: 24px;
    font-size: 25px;
  }
  .icon {
    @media (max-width: 768px) {
      display:none;
    }
  }
  .container_footer {
    @media (max-width: 768px) {
      padding-top: 50px;
      text-align: center;
    }
    @media (min-width: 768px) {
      max-width: 750px !important;
      width: 100%; 
      margin-top: -20px;
    }
    @media (min-width: 970px) {
      max-width: 1000px !important;
      width: 100%; 
    }
  }
  @media (max-width: 990px) {
    padding: 0 0 20px 0;
  }
  @media (max-width: 767px) {
    padding: 0 0 20px 0;
  }
  @media (max-width: 575px) {
    padding: 0 0 30px;
  }
  .logo {
    @media (max-width: 350px) {
      margin-top: 20px
    }
  }
  a {
    color: white;
    &:hover {
      text-decoration: underline;
      color: white;
    }
  }
`;

export const ImageWrapper = styled.section`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px 0 0 calc(100% - 190px);
  width: 190px;
  img {
    height: 55px;
    margin: 0 0 0 40px;
    @media (max-width: 1010px) {
      margin: 0 10px;
    }
    @media (max-width: 767px) {
      margin: 0 20px;
    }
    @media (max-width: 450px) {
      margin: 0 auto;
    }
  }
  @media (max-width: 575px) {
    margin: 35px auto 0;
  }
`;
